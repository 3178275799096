import React, { useEffect, useState } from "react";
import SideMenu from "../sideMenu";
import { Loader } from "../loader";
import { CustomText, PrimaryCta } from "../../components";
import { ModalComponent } from "../../components";
import Utils from "../../common/utils";
import Core from "../../common/clientSdk";
import { useSnackbar } from "react-simple-snackbar";
import Header from "../header";
import Icon from "../../components/core_component/icon";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "react-modal";

function PayoutDetailScreen(props) {
  const [isLoading, setLoading] = useState(false);
  const [kycData, setKycData] = useState([]);
  const [influencerData, setInfluencerData] = useState();
  const [contractData, setContractData] = useState();
  const [payoutListData, setPayOutListData] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [effectiveLoad, setEffectiveLoad] = useState(false);
  const [isPayoutCompleted, setIsPayoutCompleted] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // const [initiateTransferClicked, setInitiateTransferClicked] = useState(false);

  const navigate = useNavigate();

  const payout_id = useParams().id;
  console.log("payout_id", payout_id);

  const [openSnackbar] = useSnackbar();

  function updateProgress(status) {
    setLoading(status);
  }

  useEffect(() => {
    payoutDetailsAdmin(updateProgress);
  }, []);

  useEffect(() => {
    if (payoutListData) {
      setIsPayoutCompleted(payoutListData?.status);
    }
  }, [payoutListData]);

  //  -------------- Api calls -------------------

  const payoutDetailsAdmin = () => {
    const params = { payout_id: payout_id };
    Core.payoutAdminDetails(
      payoutDetailsAdminSuccess,
      payoutDetailsAdminFailed,
      updateProgress,
      params
    );
  };

  const payoutDetailsAdminSuccess = (response) => {
    console.log("response", response);
    setPayOutListData(response.payout_details || []);
    getInfluencerDetailAdmin(response.payout_details.influencer_id);
    assignmentAdminDetails(response.payout_details.assignment_id);
  };

  const payoutDetailsAdminFailed = (errorList) => {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  };

  console.log("payoutListData", payoutListData);

  function getInfluencerDetailAdmin(influencer_id) {
    var params = {
      influencer_id: influencer_id,
    };
    Core.getInfluencerAdminDetail(
      getInfluencerDetailAdminSuccess,
      getInfluencerDetailAdminFailed,
      updateProgress,
      params
    );
  }

  function getInfluencerDetailAdminSuccess(response) {
    console.log("inf res", response);
    setInfluencerData(response.influencer_deep_data || []);
    setKycData(response.influencer_deep_data.kyc_data || []);
    setLoading(false);
  }
  console.log("influencer ", influencerData);

  function getInfluencerDetailAdminFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function assignmentAdminDetails(assignment_id) {
    const params = {
      assignment_id: assignment_id,
    };
    Core.assignmentAdminDetails(
      assignmentAdminDetailsSuccess,
      assignmentAdminDetailsFailed,
      updateProgress,
      params
    );
  }

  function assignmentAdminDetailsSuccess(response_data) {
    setContractData(response_data.assignment_deep_data.assignment_data || []);
  }

  function assignmentAdminDetailsFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }
  function payoutCompleteMarkAdmin() {
    const params = {
      payout_id: payout_id,
    };
    Core.payoutCompleteMarkAdmin(
      payoutCompleteMarkAdminSuccess,
      payoutCompleteMarkAdminFailed,
      updateProgress,
      params
    );
  }

  function payoutCompleteMarkAdminSuccess() {
    toast.success("Payout mark completed!");
    closeModal();
  }

  function payoutCompleteMarkAdminFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
    closeModal();
  }

  const assignment_id = contractData?.assignment_id;

  // Core.payoutAdminInitiateTransfer   --------- [ For Button Api ] call it

  const toggleModal = () => {
    setOpenModal((prevState) => !prevState);
  };

  const handleInitateTransfer = () => {
    const params = { payout_id: payout_id };
    Core.payoutAdminInitiateTransfer(
      hanldeInitiateTranferSuccess,
      handleInitiateTransferFailed,
      (status) => setEffectiveLoad(status),
      params
    );
  };

  function hanldeInitiateTranferSuccess(response_data) {
    console.log("transfer", response_data);
    payoutDetailsAdminSuccess();
    // update the status of payment
    setOpenModal(false);
  }

  function handleInitiateTransferFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
    setOpenModal(false);
  }

  function contactDetailPage(assignment_id) {
    window.open(`/contractDetail/${assignment_id}`, "_blank");
  }

  const toFixedTwo = (value) => Number(value).toFixed(1);

  function renderkycOnConditions() {
    if (!kycData && kycData.length === 0) {
      return (
        <div className="engagement-view-container-kyc p-b-20 m-t-12">
          <CustomText
            p
            text={"No KYC is verified."}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Poppins_regular_large"}
            textAlign={"text_align_left"}
          />
        </div>
      );
    } else if (kycData.length > 0) {
      return (
        <>
          {kycData.map((kyc) => (
            <div
              key={kyc.kyc_id}
              className="engagement-view-container-kyc m-t-12"
              style={{ minHeight: "220px" }}
            >
              <div className="engagement-view-inner">
                <div className="instagram-data-view">
                  <div>
                    <div className="Kycdetails">
                      <div className="KycRight">
                        <img
                          style={{
                            minWidth: "150px",
                            objectFit: "contain",
                            height: "300px",
                            cursor: "pointer",
                          }}
                          src={Utils.generateInfluencerKycImageUrl(
                            kyc.influencer_id,
                            kyc.image_id
                          )}
                          alt="KYC Image"
                          onClick={() =>
                            window.open(
                              Utils.generateInfluencerKycImageUrl(
                                kyc.influencer_id,
                                kyc.image_id
                              ),
                              "_blank"
                            )
                          }
                        />{" "}
                      </div>
                      <div className="KycLeft kycImage">
                        <ul>
                          <li>
                            <h6>KYC Status:</h6>
                            <span>{kyc.status || "N/A"}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      );
    }
  }
  function renderBankDetails() {
    if (!influencerData.influencer_data?.is_bank_verified) {
      return (
        <div className="engagement-view-container-kyc p-l-12 m-t-12">
          <CustomText
            p
            text={"Bank is not verified."}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Poppins_regular_large"}
            textAlign={"text_align_left"}
          />
        </div>
      );
    } else {
      return (
        <div className="engagement-view-container-kyc ">
          <div className="engagement-view-inner ">
            <div className="instagram-data-view">
              <div>
                <div className="Kycdetails">
                  <div className="KycLeft">
                    <ul>
                      <li>
                        <h6>Influencer Id:</h6>
                        <span>
                          {influencerData.influencer_data?.influencer_id ||
                            "N/A"}
                        </span>
                      </li>
                      <li>
                        <h6>Account Holder:</h6>
                        <span>
                          {influencerData.bank_data?.account_holder || "N/A"}
                        </span>
                      </li>
                      <li>
                        <h6>Bank Name:</h6>
                        <span>
                          {" "}
                          {influencerData.bank_data?.bank_name || "N/A"}{" "}
                        </span>
                      </li>
                      <li>
                        <h6>Account Number:</h6>
                        <span>
                          {" "}
                          {influencerData.bank_data?.account_number ||
                            "N/A"}{" "}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="KycRight">
                    <ul>
                      <li>
                        <h6>IFSC Code:</h6>
                        <span>
                          {influencerData.bank_data?.ifsc_code || "N/A"}
                        </span>
                      </li>
                      <li>
                        <h6>Swift Code:</h6>
                        <span>
                          {" "}
                          {influencerData.bank_data?.swift_code || "N/A"}{" "}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  function renderPayoutDetails() {
    if (!payoutListData) {
      return (
        <div className="engagement-view-container-kyc p-b-12 m-t-12">
          <CustomText
            p
            text={"Payout Details are not available."}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Poppins_regular_large"}
            textAlign={"text_align_left"}
          />
        </div>
      );
    } else {
      return (
        <div>
          <div className="Kycdetails">
            <div className="KycLeft">
              <ul>
                <li>
                  <h6>Completed Date :</h6>
                  <span>
                    {" "}
                    {Utils.formatDate(payoutListData?.date_completed || "N/A")}
                  </span>
                </li>
                <li>
                  <h6>Brand Id :</h6>
                  <span>{payoutListData?.brand_id || "N/A"}</span>
                </li>
                <li>
                  <h6>Schedule Date :</h6>
                  <span>
                    {Utils.formatDate(payoutListData?.date_scheduled || "N/A")}
                  </span>
                </li>
                <li>
                  <h6> Status :</h6>
                  <span>{payoutListData?.status || "N/A"}</span>
                </li>
              </ul>
            </div>
            <div className="KycRight">
              <ul>
                <li>
                  <h6>Platform Charges :</h6>
                  <span>{payoutListData?.platform_charges || "N/A"}</span>
                </li>
                <li>
                  <h6>Order Amount :</h6>
                  <span>
                    {toFixedTwo(payoutListData?.order_amount) || "N/A"}
                  </span>
                </li>
                <li>
                  <h6>Tax Amount :</h6>
                  <span>
                    {" "}
                    {toFixedTwo(payoutListData?.tax_amount) || "N/A"}{" "}
                  </span>
                </li>
                <li>
                  <h6> Pay Amount :</h6>
                  <span>
                    {" "}
                    {toFixedTwo(payoutListData?.payout_amount) || "N/A"}{" "}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    }
  }
  function renderContractDetails() {
    if (!contractData) {
      return (
        <div className="engagement-view-container-kyc p-l-12 m-t-12">
          <CustomText
            p
            text={"Contract Details are not available."}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Poppins_regular_large"}
            textAlign={"text_align_left"}
          />
        </div>
      );
    } else {
      return (
        <div>
          <div className="Kycdetails">
            <div className="KycLeft">
              <ul>
                <li>
                  <h6>Name :</h6>
                  <span>{contractData?.name || "N/A"}</span>
                </li>
                <li>
                  <h6>Type :</h6>
                  <span> {contractData?.type || "N/A"} </span>
                </li>
                <li>
                  <h6> Assignment Id :</h6>
                  <span>{contractData?.assignment_id || "N/A"}</span>
                </li>
                <li>
                  <h6>Delivery Date :</h6>
                  <span>{Utils.formatDate(contractData?.delivery_date)}</span>
                </li>
              </ul>
            </div>
            <div className="KycRight p-l-15 p-l-15">
              <ul>
                <li>
                  <h6>Brand Id :</h6>
                  <span>{contractData?.brand_id || "N/A"}</span>
                </li>
                <li>
                  <h6> Status :</h6>
                  <span>{contractData?.assignment_status || "N/A"}</span>
                </li>

                <li>
                  <h6> Campaign Id :</h6>
                  <span>{contractData?.campaign_id || "N/A"}</span>
                </li>
                <li></li>
              </ul>
            </div>
          </div>
          <PrimaryCta
            third
            fontSize={"font_size_regular"}
            className="header-credit-right"
            text={"Contract Details"}
            onClick={() => contactDetailPage(assignment_id)}
          />
        </div>
      );
    }
  }

  const openModalFun = () => {
    if (modalIsOpen == false) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  function renderAssignmentSignModal() {
    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={CancelReminder}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            background: "#ffffff",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 54,
          }}
        >
          <div onClick={closeModal} className="close-btn-icon">
            <Icon iconName="CloseRoundButtonIcon" />
          </div>

          <CustomText
            p
            text={"Payout Completed"}
            textColor={"text_color_primary"}
            fontSize={"font_size_2xl"}
            className="typographyH7"
          />
        </div>
        <div className="create-assignment-container">
          <CustomText
            p
            fontSize={"font_size_medium"}
            textColor={"text_color_primary"}
            text={
              "Are you sure to mark this payout COMPLETED. This action can not be undo,"
            }
            noOfLine="2"
            className={"two-line-text"}
          />
        </div>
        <div className="create-assignment-container">
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              paddingTop: 20,
            }}
          >
            <button
              style={{ color: "#090a14" }}
              className="confirm-btn-bid "
              onClick={closeModal}
            >
              Close
            </button>
            <div className=" m-l-32">
              <PrimaryCta
                fifth
                onClick={() => payoutCompleteMarkAdmin()}
                fontSize={"font_size_large"}
                className="collection-create-btn"
                text={"Mark Completed"}
                isLoading={isLoading}
                //   disabled={!collectionId}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <div className="dashboard-container">
      <SideMenu />
      <div className="main-screen">
        <Header />
        <div className="page-container-campaignList">
          {isLoading ? (
            <div className="loader-mid">
              <Loader />
            </div>
          ) : (
            <div>
              <div className="m-b-16 m-l-20 m-r-20 flex-jus-sp-al-center">
                <CustomText
                  h3
                  text={"Payout Details"}
                  textColor={"text_color_primary"}
                  fontSize={"font_size_4xl"}
                  height={"line_height_24"}
                />
                <PrimaryCta
                  sixth
                  textColor={"text_color_light"}
                  fontSize={"font_size_large"}
                  text={"Mark Completed"}
                  className="unboost-campaigns"
                  onClick={openModalFun}
                />
              </div>
              {/* ------------- KYC -----------------  */}
              <div className="influencer-detail-category-modal-platform">
                <div class="m-l-20">
                  <div className="m-b-12 ">
                    <div style={{ gap: 20 }} className="flex-row">
                      <CustomText
                        p
                        text={"KYC Details"}
                        fontSize={"font_size_large"}
                        textColor={"text_color_primary"}
                        fontWeight={"Poppins_regular_large"}
                        textAlign={"text_align_left"}
                      />
                    </div>

                    {influencerData ? (
                      renderkycOnConditions()
                    ) : (
                      <div className="engagement-view-container-kyc p-l-15 m-t-12">
                        <CustomText
                          p
                          text={"No KYC is verified."}
                          fontSize={"font_size_large"}
                          textColor={"text_color_primary"}
                          fontWeight={"Poppins_regular_large"}
                          textAlign={"text_align_left"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* ----------- Bank details ------------------- */}
              <div className="influencer-detail-category-modal-platform">
                <div class="m-l-20 ">
                  <div className="p-l-15">
                    <CustomText
                      p
                      text={"Bank Details"}
                      fontSize={"font_size_large"}
                      textColor={"text_color_primary"}
                      fontWeight={"Poppins_regular_large"}
                      textAlign={"text_align_left"}
                    />

                    {influencerData ? (
                      renderBankDetails()
                    ) : (
                      <div className="engagement-view-container-kyc p-l-15 m-t-12">
                        <CustomText
                          p
                          text={"Bank is not  verified."}
                          fontSize={"font_size_large"}
                          textColor={"text_color_primary"}
                          fontWeight={"Poppins_regular_large"}
                          textAlign={"text_align_left"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* ------ Payout  detail  ---------  */}
              <div className="influencer-detail-category-modal-platform">
                <div class="m-l-20 ">
                  <div className="p-b-20">
                    <CustomText
                      p
                      text={"Payout Details "}
                      fontSize={"font_size_large"}
                      textColor={"text_color_primary"}
                      fontWeight={"Poppins_regular_large"}
                      textAlign={"text_align_left"}
                    />
                  </div>

                  <div className="engagement-view-container-kyc">
                    <div className="engagement-view-inner ">
                      <div className="instagram-data-view">
                        {payoutListData ? (
                          renderPayoutDetails()
                        ) : (
                          <div className="p-l-15">
                            <CustomText
                              p
                              text={"Payout Details are not available."}
                              fontSize={"font_size_large"}
                              textColor={"text_color_primary"}
                              fontWeight={"Poppins_regular_large"}
                              textAlign={"text_align_left"}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* ---------- Contract Details -------- */}

              <div className="influencer-detail-category-modal-platform">
                <div class="m-l-20">
                  <div className="p-b-20">
                    <CustomText
                      p
                      text={"Contract Details "}
                      fontSize={"font_size_large"}
                      textColor={"text_color_primary"}
                      fontWeight={"Poppins_regular_large"}
                      textAlign={"text_align_left"}
                    />
                  </div>

                  <div className="engagement-view-container-kyc">
                    <div className="engagement-view-inner ">
                      <div className="instagram-data-view">
                        {contractData ? (
                          renderContractDetails()
                        ) : (
                          <div className="engagement-view-container-kyc p-l-15 m-t-12">
                            <CustomText
                              p
                              text={"Contract Details are not available."}
                              fontSize={"font_size_large"}
                              textColor={"text_color_primary"}
                              fontWeight={"Poppins_regular_large"}
                              textAlign={"text_align_left"}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* ---------- api call button ---------- */}

              <div className="m-l-8 buttons-container ">
                <PrimaryCta
                  third
                  fontSize={"font_size_regular"}
                  className="header-credit-right"
                  style={{
                    cursor:
                      isPayoutCompleted === "COMPLETED"
                        ? "not-allowed"
                        : "pointer",
                  }}
                  text={
                    isPayoutCompleted == "COMPLETED"
                      ? "Transfer Completed"
                      : "Initiate Transfer"
                  }
                  disabled={isPayoutCompleted == "COMPLETED"}
                  onClick={() => toggleModal()}
                />
              </div>
              {modalIsOpen && renderAssignmentSignModal()}
              {/*  ----------- Confirmartion modal ---------- */}
              <ModalComponent
                isOpen={openModal}
                onRequestClose={() => toggleModal()}
                customStyle={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    zIndex: 999999,
                  },
                  content: {
                    top: "50%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    // borderColor: "#f55879",
                    backgroundColor: "#F0F3F9",

                    marginRight: "-50%",
                    transform: "translate(-50%, -50%)",
                    padding: "20px",
                    borderRadius: "8px",
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
                  },
                }}
              >
                <div className="">
                  <CustomText
                    h5
                    text={"Are you sure For Initiate Transfer?"}
                    textColor={"text_grey_400_color"}
                    className="m-b-8 typographyBoldH5"
                  />
                </div>

                <div className="modal-btn-center">
                  <PrimaryCta
                    invert
                    className="back-btn-campaign"
                    onClick={() => toggleModal()}
                    fontSize={"font_size_large"}
                    text={"No"}
                  />

                  <PrimaryCta
                    third
                    fontSize={"font_size_regular"}
                    className="header-credit-right"
                    text={"Yes"}
                    isLoading={effectiveLoad}
                    onClick={() => handleInitateTransfer()}
                  />
                </div>
              </ModalComponent>
            </div>

            // -------------- in last Button ------------
          )}
        </div>
      </div>
    </div>
  );
}

export default PayoutDetailScreen;
const CancelReminder = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    textAlign: "center",
    zIndex: "5",
    height: "20%",
    display: "flex",
    flexDirection: "column",
    minHeight: "200px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.95)",
    zIndex: 10,
  },
};
