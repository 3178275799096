import React from "react";
import {
  CustomBox,
  CustomText,
  FallBack,
  Image,
  TopHeading,
  PrimaryCta,
  CampaignList,
  AssignmentList,
  FilterBtn,
  Video,
  AnalyticsCard,
} from "../components";
import SideMenu from "./sideMenu";
import Header from "./header";
import MathPlus from "../resources/images/math-plus.svg";
import RightArrowO from "../resources/images/arrow-right-circle-thin.svg";
import Instagram from "../resources/images/instagram.svg";
import Creator from "../resources/images/creator.png";
import { Link, useNavigate } from "react-router-dom";

import { NavLink } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";
import InfiniteScroll from "react-infinite-scroll-component";
import utils from "../common/utils";
import Core from "../common/clientSdk";
import { useSnackbar } from "react-simple-snackbar";
//import { Tooltip as ReactTooltip } from "react-tooltip";
import Tooltip from "@mui/material/Tooltip";
import Style from "../components/core_component/style";
import * as PreviewCampaign from "../common/data/preview_campaign";

// import CanvasJSReact from "@canvasjs/react-charts";

// const CanvasJSChart = CanvasJSReact.CanvasJSChart;
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ToastContainer, toast } from "react-toastify";
import Sdk from "../common/core/module";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import CampaignListingScreen from "./campaignListingScreen";
import CustomTable from "../components/customTable";
import { Loader } from "./loader";
import AssignmentListScreen from "./campaignDetailsParent/assignmentListScreen";
import Utils from "../common/utils";
import ReportSidebar from "./report-sidebar";
import ReactGA from "react-ga";
import Icon from "../components/core_component/icon";
import ReviewCampaign from "./createCampaignScreen/reviewCampaign";
import QuickCreateCampaign from "./createCampaignScreen/quickCreateCampaign";

// import { messaging, requestNotificationPermission } from "../common/firebase";
const limit = 10;

const videooSample = require("../resources/video/beauty.mp4");
const DashboardScreen = function (props) {
  const [assignmentList, setAssignmentList] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [start, setStart] = useState(0);
  const [loading, setLoading] = useState(true);
  const [next, setNext] = useState(0);
  const [orgData, setOrgData] = useState({});
  const [address, setAddress] = useState({});
  const [creditsDetail, setCreditsDetail] = useState({});
  const [activeTab, setActiveTab] = useState("instagram");
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoveredVideo, setHoveredVideo] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(null);
  const [modalIsOpenQuick, setModalIsOpenQuick] = useState(false);
  const [bundleType, setBundleType] = useState("FASHION_BEAUTY");
  const [isLoading, setIsLoading] = useState(false);
  const [adminReport, setAdminReport] = useState({});
  const [openSnackbar] = useSnackbar();

  const [statusColors, setStatusColors] = useState({
    ACTIVE: "#E89822",
    INACTIVE: "red",
    COMPLETED: "#68D184",
    IN_REVIEW: "#1977f2",
  });

  var navigate = useNavigate();

  useEffect(() => {
    getAdminReportDetails();
  }, []);

  function updateProgress(status) {
    setLoading(status);
  }

  function getAdminReportDetails() {
    setIsLoading(true);
    var params = {};
    Core.getAdminReportDetails(
      getAdminReportDetailsSuccess,
      getAdminReportDetailsFailed,
      updateProgress,
      params
    );
  }

  function getAdminReportDetailsSuccess(response) {
    setAdminReport(response);
  }

  function getAdminReportDetailsFailed(errorList) {
    console.log("error list", errorList);

    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  // Format Lagre Number function - 485
  // const formatNumber = (num) => {
  //   if (num >= 1000000) {
  //     return (num / 1000000).toFixed(1) + "M";
  //   } else if (num >= 10000) {
  //     return (num / 1000).toFixed(1) + "K";
  //   } else {
  //     return num;
  //   }
  // };

  return (
    <div className="dashboard-container">
      <SideMenu />
      <div className="main-screen">
        <Header />
        <div className="page-container">
          {/* <div className="quick-create-campaign-container">
            <div className="campaign-detail-upper-container">
              <div className="campaign-detail-tab-header">
                <div
                  className={activeTab === "instagram" ? "active" : ""}
                  onClick={() => handleTabClick("instagram")}
                >
                  <div className="quick-campaign-detail-btn-tabs">
                    <Icon iconName="instagramNewIcon" />
                    <CustomText
                      p
                      // textColor={"text_color_primary"}
                      fontSize={"font_size_extra_large"}
                      text={"Instagram"}
                      className="button2"
                    />
                  </div>
                </div>
                <div
                  className={activeTab === "proposal-page" ? "active" : ""}
                  onClick={() => handleTabClick("proposal-page")}
                >
                  <div className="quick-campaign-detail-btn-tabs">
                    <Icon iconName="youtubeNewIcon" />
                    <CustomText
                      p
                      // textColor={"text_color_primary"}
                      fontSize={"font_size_extra_large"}
                      text={"Youtube"}
                      className="button2"
                    />
                  </div>
                </div>
                <div
                  className={activeTab === "contracts-page" ? "active" : ""}
                  onClick={() => handleTabClick("contracts-page")}
                >
                  <div className="quick-campaign-detail-btn-tabs">
                    <Icon iconName="xAppNewIcon" />
                    <CustomText
                      p
                      // textColor={"text_color_primary"}
                      fontSize={"font_size_extra_large"}
                      text={"X"}
                      className="button2"
                    />
                  </div>
                </div>
              </div>
            </div>
            {activeTab === "instagram" && <>{quickCampaignCreateCard()}</>}
            {activeTab === "proposal-page" && "youtube"}
            {activeTab === "contracts-page" && "X app"}
          </div> */}

          <div>
            {loading ? (
              <div className="loader-mid">
                <Loader />
              </div>
            ) : (
              <>
                <div className="campaignlist-header ">
                  {/* <CustomText
                    p
                    text={"Brand Level Analytics"}
                    textColor={"text_color_primary"}
                    fontSize={"font_size_2xl"}
                    className="typographyH7"
                  /> */}
                </div>

                <div className="analytics-card-container m-l-10 ">
                  <div className="analytics-card-wrap">
                    <div className="row-1">
                      <AnalyticsCard
                        title={"Campaigns"}
                        value={Utils.changeNumberFormate(
                          adminReport.total_campaigns
                        )}
                        iconName="viewEyes"
                      />
                      <AnalyticsCard
                        title={"Organisations"}
                        value={Utils.changeNumberFormate(
                          adminReport.total_orgs
                        )}
                        iconName="heartLikeIcon"
                      />
                      <AnalyticsCard
                        title={"Brands"}
                        value={Utils.changeNumberFormate(
                          adminReport.total_brands
                        )}
                        iconName="rupeeIcon"
                      />
                      <AnalyticsCard
                        title={"Influencer"}
                        value={Utils.changeNumberFormate(
                          adminReport.total_influencers
                        )}
                        iconName="AssignmentIconActive"
                      />
                    </div>

                    <div className="row-1">
                      <AnalyticsCard
                        title={"Influencers with Contracts"}
                        value={Utils.changeNumberFormate(
                          adminReport.distict_influencer_worked
                        )}
                        iconName="AssignmentIconActive"
                      />
                      <AnalyticsCard
                        title="Commissions"
                        value={Utils.changeNumberFormate(
                          adminReport.total_commition
                        )}
                        iconName="AssignmentIconActive"
                      />
                      <AnalyticsCard
                        title={"Influencer Credit"}
                        value={Utils.changeNumberFormate(
                          adminReport.influencer_credit_purchase
                        )}
                        iconName="AssignmentIconActive"
                      />

                      <AnalyticsCard
                        title={"ORG Credit"}
                        value={Utils.changeNumberFormate(
                          adminReport.org_credit_purchase
                        )}
                        iconName="AssignmentIconActive"
                      />
                    </div>
                    <div className="row-1">
                      <AnalyticsCard
                        title={"Live Contents"}
                        value={Utils.changeNumberFormate(
                          adminReport.total_content_live
                        )}
                        iconName="AssignmentIconActive"
                      />
                      <AnalyticsCard
                        title={"Payout Generated"}
                        value={Utils.changeNumberFormate(
                          adminReport.total_payout_generated
                        )}
                        iconName="AssignmentIconActive"
                      />
                      <AnalyticsCard
                        title={"Payout Processed"}
                        value={Utils.changeNumberFormate(
                          adminReport.total_payout_completed
                        )}
                        iconName="AssignmentIconActive"
                      />
                      <AnalyticsCard
                        title={"Payment Pending"}
                        value={Utils.changeNumberFormate(
                          adminReport.amount_to_be_paid_assignment
                        )}
                        iconName="AssignmentIconActive"
                      />
                    </div>
                    <div className="row-1">
                      <AnalyticsCard
                        title={"Wallet Balance "}
                        value={Utils.changeNumberFormate(
                          adminReport.remaining_balance_org
                        )}
                        iconName="AssignmentIconActive"
                      />
                      <AnalyticsCard
                        title={"Total Assignment Cost"}
                        value={Utils.changeNumberFormate(
                          adminReport.total_reward_assignment
                        )}
                        iconName="AssignmentIconActive"
                      />
                      <AnalyticsCard
                        title={"Barter Earning"}
                        value={Utils.changeNumberFormate(
                          adminReport.total_barter_earning
                        )}
                        iconName="AssignmentIconActive"
                      />
                    </div>
                  </div>
                </div>

                {/* <>
                  {assignmentList && assignmentList.length > 0 && (
                    <div>
                      <div className="campaign-text-heading see-all-button">
                        <CustomText
                          p
                          text={"Active Contracts"}
                          textColor={"text_color_primary"}
                          fontSize={"font_size_2xl"}
                        />
                        <div
                          className="see-all-main-btn"
                          onClick={() => {
                            navigate("/contracts");
                          }}
                        >
                          <CustomText
                            p
                            text={"See All"}
                            textColor={"text_color_primary"}
                            fontSize={"font_size_medium"}
                          />
                        </div>
                      </div>
                      {assignmentList.slice(0, 3).map((row) => (
                        <div className="space-list ">
                          <AssignmentList
                            onClick={() =>
                              navigateToAssignmentDetails(
                                row.assignment_data.assignment_id
                              )
                            }
                            source={
                              row.influencer_data.profile_picture
                                ? utils.generateInfluencerDpUrl(
                                    row.influencer_data.influencer_id,
                                    row.influencer_data.profile_picture
                                  )
                                : require("../resources/images/user-copy.png")
                            }
                            influencerName={row.influencer_data.name}
                            rewardText={Utils.formatRewardText(row.reward_data)}
                            deliverablesText={Utils.generateAssignmentSubText(
                              row
                            )}
                            status={Utils.assignmentStatusToString(
                              row.assignment_data.assignment_status
                            )}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </> */}
                {/* 
                {campaignList.length > 0 ? (
                  <>
                    <div className="campaign-text-heading see-all-button">
                      <CustomText
                        p
                        text={"Latest Campaigns"}
                        textColor={"text_color_primary"}
                        fontSize={"font_size_2xl"}
                      />
                      <div
                        className="see-all-main-btn"
                        onClick={() => {
                          navigate("/campaigns");
                        }}
                      >
                        <CustomText
                          p
                          text={"See All"}
                          textColor={"text_color_primary"}
                          fontSize={"font_size_medium"}
                        />
                      </div>
                    </div>

                    {campaignList.slice(0, 3).map((item, index) => {
                      return (
                        <div
                          style={{
                            color: statusColors[item.campaign_data.status],
                          }}
                          className="space-list"
                        >
                          <CampaignList
                            onClick={() =>
                              CampaignClick(item.campaign_data.campaign_id)
                            }
                            key={index}
                            source={
                              item.brand_data.image_id
                                ? utils.generateBrandLogoUrl(
                                    item.brand_data.org_id,
                                    item.brand_data.image_id
                                  )
                                : require("../resources/images/user-copy.png")
                            }
                            title={item.campaign_data.name}
                            // date={"a day"}
                            type={utils.getCampaignType(
                              item.campaign_data.type
                            )}
                            View={item.campaign_data.seen_count || "0"}
                            Bids={item.campaign_data.bid_count}
                            Contract={item.campaign_data.assignment_count}
                            Status={utils.assignmentStatusToString(
                              item?.campaign_data?.status
                            )}
                            Posted={utils.convertUnixToDate(
                              item.campaign_data.end_date
                            )}
                          />
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <div className="fallback">
                      <FallBack
                        heading={"No live campaigns"}
                        title={"Launch a new campaign"}
                      />

                      <div className="m-t-15">
                        <PrimaryCta
                          third
                          // onClick={() => createCampaign()}
                          fontSize={"font_size_regular"}
                          className="create-campaign"
                          text={"Create Campaign"}
                        />
                      </div>
                    </div>
                  </>
                )} */}
              </>
            )}
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        {/* {modalIsOpen && <ReviewCampaign closeModal={closeModal} />} */}

        {/* {modalIsOpenQuick && (
          <QuickCreateCampaign
            bundleType={bundleType}
            closeModal={closeModalQuick}
            setModalIsOpen={setModalIsOpen}
            modalIsOpen={modalIsOpen}
          />
        )} */}
      </div>
    </div>
  );
};

export default DashboardScreen;
