import React, { useEffect, useState } from "react";
import Core from "../../common/clientSdk";
import { useSnackbar } from "react-simple-snackbar";
import SideMenu from "../sideMenu";
import { Loader } from "../loader";
import { useNavigate, useParams } from "react-router-dom";
import {
  CustomText,
  CustomTextArea,
  CustomTextInput,
  FallBack,
  ModalComponent,
  PrimaryCta,
} from "../../components";
import Utils from "../../common/utils";
import { Icon } from "@mui/material";
import { toast } from "react-toastify";
import Header from "../header";

function KycDetailsPage() {
  const [Kycdetails, setKycaDetails] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [effectiveLoad, setEffectiveLoad] = useState(false);
  const [influencerData, setInfluencerData] = useState();

  const [isApproveModalOpen, setApproveModalOpen] = useState(false);

  const [openSnackbar] = useSnackbar();

  const kyc_id = useParams().id;

  useEffect(() => {
    getAdminKycDetail(updateProgress);
  }, []);

  function updateProgress(status) {
    setLoading(status);
  }

  function getAdminKycDetail() {
    var params = {
      kyc_id: kyc_id,
    };
    Core.getAdminKycDetail(
      getAdminKycDetailSuccess,
      getAdminKycDetailFailed,
      updateProgress,
      params
    );
  }

  function getAdminKycDetailSuccess(response) {
    console.log("res", response);
    setKycaDetails(response.kyc_data);
    if (response.kyc_data?.influencer_id) {
      getInfluencerDetailAdmin(response.kyc_data?.influencer_id);
    }

    setLoading(false);
  }
  console.log("details", Kycdetails);

  function getAdminKycDetailFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  // -------- Influncer Data ----------

  function getInfluencerDetailAdmin(influencer_id) {
    var params = {
      influencer_id: influencer_id,
    };
    Core.getInfluencerAdminDetail(
      getInfluencerDetailAdminSuccess,
      getInfluencerDetailAdminFailed,
      updateProgress,
      params
    );
  }

  function getInfluencerDetailAdminSuccess(response) {
    console.log("inf res", response);
    setInfluencerData(response.influencer_deep_data || []);
    // setKycData(response.influencer_deep_data.kyc_data || []);
    setLoading(false);
  }
  console.log("influencer ", influencerData);

  function getInfluencerDetailAdminFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  //  ---------- kyc Approve and Rejects ----------

  // KYC Rejected Or Accepted Function ApiCall

  const KycRejected = () => {
    const params = {
      kyc_id: Kycdetails.kyc_id,
      comment: comment,
      is_approved: "false",
    };
    Core.postAdminVerify(
      KycRejectionSuccess,
      KycRejectionFailed,
      (status) => setEffectiveLoad(status),
      params
    );
  };

  function KycRejectionSuccess(response) {
    console.log("kyc rejection response ", response);
    getAdminKycDetail();
    closeRejectModal();
  }

  function KycRejectionFailed(error) {
    toast.error(error.map((item) => item.m).join(""));
  }

  const KycAccepted = () => {
    const params = {
      kyc_id: Kycdetails.kyc_id,
      is_approved: "true",
    };
    Core.postAdminVerify(
      KycAcceptedSuccess,
      KycAcceptedFailed,
      (status) => setEffectiveLoad(status),
      params
    );
  };

  function KycAcceptedSuccess(response) {
    // console.log(response);
    getAdminKycDetail();
    closeApproveModal();
  }

  function KycAcceptedFailed(error) {
    toast.error(error.map((item) => item.m).join(""));
  }

  const openApproveModal = () => setApproveModalOpen(true);
  const closeApproveModal = () => setApproveModalOpen(false);

  const openRejectModal = () => setRejectModalOpen(true);
  const closeRejectModal = () => setRejectModalOpen(false);
  const handleInputChange = (e) => setComment(e.target.value);

  function renderKycImage() {
    return (
      <>
        <div className="kycImage">
          <img
            style={{
              width: "270px",
              objectFit: "contain",
              height: "300px",
              cursor: "pointer",
            }}
            src={Utils.generateInfluencerKycImageUrl(
              Kycdetails?.influencer_id,
              Kycdetails?.image_id
            )}
            alt="KYC Image"
            onClick={() =>
              window.open(
                Utils.generateInfluencerKycImageUrl(
                  Kycdetails?.influencer_id,
                  Kycdetails?.image_id
                ),
                "_blank"
              )
            }
          />
        </div>
      </>
    );
  }

  function renderApproveReject() {
    if (Kycdetails.status !== "IN_REVIEW") return null;
    return (
      <div className="kycImage">
        {/* Approve and Reject buttons */}
        <div>
          <PrimaryCta
            invert
            className="back-btn-campaign"
            onClick={openRejectModal}
            fontSize="font_size_large"
            text="Reject"
          />

          <PrimaryCta
            fifth
            fontSize="font_size_regular"
            className="header-credit-right"
            text="Accept"
            isLoading={effectiveLoad}
            onClick={openApproveModal}
          />
        </div>

        {/* Approve Confirmation Modal */}
        <ModalComponent
          isOpen={isApproveModalOpen}
          onRequestClose={closeApproveModal}
          customStyle={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 999999,
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              backgroundColor: "#F0F3F9",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
            },
          }}>
          <div className="modal-header">
            <CustomText
              h5
              text="Are you sure you want to approve this KYC?"
              textColor="text_grey_400_color"
              className="m-b-8 typographyBoldH5"
            />
          </div>

          <div className="modal-actions modal-btn-center">
            <PrimaryCta
              invert
              className="back-btn-campaign"
              onClick={closeApproveModal}
              fontSize="font_size_large"
              text="Close"
            />

            <PrimaryCta
              third
              fontSize="font_size_regular"
              className="header-credit-right"
              text="Yes"
              isLoading={effectiveLoad}
              onClick={() => KycAccepted(Kycdetails.kyc_id)}
            />
          </div>
        </ModalComponent>

        {/* Reject Confirmation Modal */}
        <ModalComponent
          isOpen={isRejectModalOpen}
          onRequestClose={closeRejectModal}
          customStyle={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 999999,
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              backgroundColor: "#F0F3F9",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
            },
          }}>
          <div onClick={closeRejectModal} className="close-btn-icon">
            <Icon iconName="CloseRoundButtonIcon" />
          </div>

          <div className="modal-header">
            <CustomText
              h5
              text="Reject KYC"
              textColor="text_grey_400_color"
              className="m-b-8 typographyBoldH5"
            />
          </div>

          <div className="m-t-20 brand-text-area">
            <CustomTextArea
              invert
              className="custom-text-offer"
              rows={4}
              value={comment}
              onChange={handleInputChange}
            />
          </div>
          <div className="modal-btn-center">
            <PrimaryCta
              invert
              className="back-btn-campaign"
              onClick={closeRejectModal}
              fontSize="font_size_large"
              text="Close"
            />

            <PrimaryCta
              third
              fontSize="font_size_regular"
              className="header-credit-right"
              text="Reject"
              isLoading={effectiveLoad}
              onClick={() => KycRejected(Kycdetails.kyc_id)}
            />
          </div>
        </ModalComponent>
      </div>
    );
  }

  function renderKycDetails() {
    return (
      <div className="engagement-view-inner ">
        <div className="instagram-data-view">
          <div>
            <div className="Kycdetails">
              <div className="KycLeft">
                <ul>
                  <li>
                    <h6>Kyc Type:</h6>
                    <span>
                      {Utils.replaceUnderscoreWithSpace(Kycdetails?.kyc_type)}
                    </span>
                  </li>
                  <li>
                    <h6> Status :</h6>
                    <span>
                      {Utils.replaceUnderscoreWithSpace(Kycdetails?.status)}
                    </span>
                  </li>
                  <li>
                    <h6>Kyc Id:</h6>
                    <span> {Kycdetails?.kyc_id || "N/A"} </span>
                  </li>
                </ul>
              </div>
              <div className="KycRight">
                <ul>
                  <li>
                    <h6>Actor Id:</h6>
                    <span> {Kycdetails?.actor_id || "N/A"} </span>
                  </li>
                  <li>
                    <h6>Actor Type:</h6>
                    <span> {Kycdetails?.actor_type || "N/A"} </span>
                  </li>
                  <li>
                    <h6>Kyc Value:</h6>
                    <span> {Kycdetails?.kyc_value || "N/A"} </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  function renderInfluencerDetails() {
    if (!influencerData?.influencer_data) {
      return (
        <div className="engagement-view-container-kyc p-l-12 m-t-12">
          <CustomText
            p
            text={"Bank is not verified."}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Poppins_regular_large"}
            textAlign={"text_align_left"}
          />
        </div>
      );
    } else {
      return (
        <div className="engagement-view-container-kyc m-t-12">
          <div className="engagement-view-inner">
            <div className="instagram-data-view">
              <div>
                <div className="Kycdetails">
                  <div className="KycLeft">
                    <ul>
                      <li>
                        <h6>Influencer Id:</h6>
                        <span>
                          {influencerData.influencer_data?.influencer_id ||
                            "N/A"}
                        </span>
                      </li>
                      <li>
                        <h6>Influencer Name:</h6>
                        <span>
                          {influencerData.influencer_data?.name || "N/A"}
                        </span>
                      </li>

                      <li>
                        <h6>Gender:</h6>
                        <span>
                          {influencerData.influencer_data?.gender || "N/A"}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="KycRight">
                    <ul>
                      <li>
                        <h6>Language:</h6>
                        <span>
                          {influencerData.influencer_data?.language || "N/A"}
                        </span>
                      </li>
                      <li>
                        <h6>Phone No:</h6>
                        <span>
                          {influencerData.influencer_data?.phone || "N/A"}
                        </span>
                      </li>
                      <li>
                        <h6>Profile Status:</h6>
                        <span>
                          {influencerData.influencer_data?.profile_status ||
                            "N/A"}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  function renderAddressDetails() {
    if (!influencerData?.address_data) {
      return (
        <div className="engagement-view-container-kyc p-l-12 m-t-12">
          <CustomText
            p
            text={"Address is not Available."}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Poppins_regular_large"}
            textAlign={"text_align_left"}
          />
        </div>
      );
    } else {
      return (
        <div className="engagement-view-container-kyc ">
          <div className="engagement-view-inner ">
            <div className="instagram-data-view">
              <div>
                <div className="Kycdetails">
                  <div className="KycLeft">
                    <ul>
                      <li>
                        <h6>Address Id:</h6>
                        <span>
                          {influencerData.address_data?.address_id || "N/A"}
                        </span>
                      </li>
                      <li>
                        <h6>Address premise:</h6>
                        <span>
                          {" "}
                          {influencerData.address_data?.address_premise ||
                            "N/A"}{" "}
                        </span>
                      </li>
                      <li>
                        <h6>Adress Street:</h6>
                        <span>
                          {" "}
                          {influencerData.address_data?.address_street ||
                            "N/A"}{" "}
                        </span>
                      </li>
                      <li>
                        <h6>Address Pincode:</h6>
                        <span>
                          {" "}
                          {influencerData.address_data?.address_pincode ||
                            "N/A"}{" "}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="KycRight">
                    <ul>
                      <li>
                        <h6>Address Country:</h6>
                        <span>
                          {influencerData.address_data?.address_country ||
                            "N/A"}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  function renderBankDetails() {
    if (!influencerData?.influencer_data?.is_bank_verified) {
      return (
        <div className="engagement-view-container-kyc p-l-12 m-t-12">
          <CustomText
            p
            text={"Bank is not verified."}
            fontSize={"font_size_large"}
            textColor={"text_color_primary"}
            fontWeight={"Poppins_regular_large"}
            textAlign={"text_align_left"}
          />
        </div>
      );
    } else {
      return (
        <div className="engagement-view-container-kyc ">
          <div className="engagement-view-inner ">
            <div className="instagram-data-view">
              <div>
                <div className="Kycdetails">
                  <div className="KycLeft">
                    <ul>
                      <li>
                        <h6>Influencer Id:</h6>
                        <span>
                          {influencerData.influencer_data?.influencer_id ||
                            "N/A"}
                        </span>
                      </li>
                      <li>
                        <h6>Account Holder:</h6>
                        <span>
                          {influencerData.bank_data?.account_holder || "N/A"}
                        </span>
                      </li>
                      <li>
                        <h6>Bank Name:</h6>
                        <span>
                          {" "}
                          {influencerData.bank_data?.bank_name || "N/A"}{" "}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="KycRight">
                    <ul>
                      <li>
                        <h6>Account Number:</h6>
                        <span>
                          {" "}
                          {influencerData.bank_data?.account_number ||
                            "N/A"}{" "}
                        </span>
                      </li>
                      <li>
                        <h6>IFSC Code:</h6>
                        <span>
                          {influencerData.bank_data?.ifsc_code || "N/A"}
                        </span>
                      </li>
                      <li>
                        <h6>Swift Code:</h6>
                        <span>
                          {" "}
                          {influencerData.bank_data?.swift_code || "N/A"}{" "}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="dashboard-container">
      <SideMenu />
      <div className="main-screen">
        <Header />
        <div className="page-container-campaignList">
          {isLoading ? (
            <div className="loader-mid">
              <Loader />
            </div>
          ) : (
            <>
              <div className="m-b-12 m-l-20">
                <CustomText
                  p
                  text={
                    influencerData ? influencerData.influencer_data?.name : ""
                  }
                  textColor={"text_color_gray_500"}
                  fontSize={"font_size_h7"}
                  textAlign={"text_align_left"}
                  fontWeight={"Inter_Bold"}
                  lineHeight={"line_height_24"}
                />
              </div>

              <div className="m-b-12 m-l-20">
                <CustomText
                  p
                  text={"Kyc Details"}
                  textColor={"text_color_gray_500"}
                  fontSize={"font_size_h7"}
                  textAlign={"text_align_left"}
                  fontWeight={"Inter_Bold"}
                  lineHeight={"line_height_24"}
                />
              </div>

              {/* ---- Details Input ------ */}
              <div
                className="engagement-view-container-kyc"
                style={{
                  minHeight: "250px",
                  marginLeft: "20px",
                  alignItems: "flex-start",
                }}>
                {renderKycDetails()}

                {/* ----- Image ------ */}

                {renderKycImage()}

                {/* -------- kyc Approve and Reject  buttons  */}
                {renderApproveReject()}
              </div>

              {/* -------- Influencer  Details ------- */}

              <div
                className="influencer-detail-category-modal-platform"
                style={{ marginLeft: "10px" }}>
                <div className="m-l-2">
                  <div className="p-l-15 ">
                    <CustomText
                      p
                      text={"Influencer Details"}
                      fontSize={"font_size_large"}
                      textColor={"text_color_primary"}
                      fontWeight={"Poppins_regular_large"}
                      textAlign={"text_align_left"}
                    />
                    {influencerData ? (
                      renderInfluencerDetails()
                    ) : (
                      <div className="engagement-view-container-kyc p-l-15 m-t-12">
                        <CustomText
                          p
                          text={"Influencer Details Not Available."}
                          fontSize={"font_size_large"}
                          textColor={"text_color_primary"}
                          fontWeight={"Poppins_regular_large"}
                          textAlign={"text_align_left"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* ------ Address details ---------- */}

              <div
                className="influencer-detail-category-modal-platform"
                style={{ marginLeft: "10px" }}>
                <div className="m-l-2">
                  <div className="p-l-15 ">
                    <CustomText
                      p
                      text={"Address Details"}
                      fontSize={"font_size_large"}
                      textColor={"text_color_primary"}
                      fontWeight={"Poppins_regular_large"}
                      textAlign={"text_align_left"}
                    />
                    {influencerData ? (
                      renderAddressDetails()
                    ) : (
                      <div className="engagement-view-container-kyc p-l-15 m-t-12">
                        <CustomText
                          p
                          text={"Address Details Not Available."}
                          fontSize={"font_size_large"}
                          textColor={"text_color_primary"}
                          fontWeight={"Poppins_regular_large"}
                          textAlign={"text_align_left"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* -------- Bank details */}
              <div
                className="influencer-detail-category-modal-platform"
                style={{ marginLeft: "10px" }}>
                <div className="m-l-2">
                  <div className="p-l-15">
                    <CustomText
                      p
                      text={"Bank Details"}
                      fontSize={"font_size_large"}
                      textColor={"text_color_primary"}
                      fontWeight={"Poppins_regular_large"}
                      textAlign={"text_align_left"}
                    />
                    {influencerData ? (
                      renderBankDetails()
                    ) : (
                      <div className="engagement-view-container-kyc p-l-15 m-t-12">
                        <CustomText
                          p
                          text={"Bank is not verified."}
                          fontSize={"font_size_large"}
                          textColor={"text_color_primary"}
                          fontWeight={"Poppins_regular_large"}
                          textAlign={"text_align_left"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
export default KycDetailsPage;
