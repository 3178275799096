import React, { useEffect, useState } from "react";
import {
    CustomText,CustomTextInput,
    Image,
    PrimaryCta,
  } from "../../components";
import { useSnackbar } from "react-simple-snackbar";
import Style from "../../resources/style";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../redux/actions";
import { toast } from "react-toastify";
import Core from '../../common/clientSdk';
import Utils from "../../common/utils";
import Multiselect from "multiselect-react-dropdown";
import * as CampaignData from "../../common/data/campaign";
import validator from "validator";
const OrganisationDetailsScreen=(props)=>{
const [organisationData,setOrganisationData]=useState({})
const [orgAddressData,setOrgAddressData]=useState({});
const [isLoading, setLoading] = useState(false);
const [orgName, setOrgName] = useState("");
const [email, setEmail] = useState("");
const [phoneNumber,setPhoneNumber]=useState("");
const [registerDate,setRegisterDate]=useState("");
const [rating,setRating]=useState("")
const [orgId,setOrgId]=useState("");
const [pan, setPan] = useState("");
const [gst, setGst] = useState("");
const [premise, setPremise] = useState("");
const [street, setStreet] = useState("");
const [city, setCity] = useState("");
const [state,setState]=useState("");
const [pinCode, setPinCode] = useState("");
const [country, setCountry] = useState("India");
const [credit,setCredits]=useState("");
const [balance,setBalance]=useState("")
const [error, set_error] = useState({
    orgName: "",
    premise: "",
    street: "",
    city: "",
    state: "",
    country: "",
    pinCode: "",
    email: "",
    pan: "",
    gst: "",
    phoneNumber: "",
    registerDate:"",
    rating:"",
    orgId:"",
    credit:"",
    balance:""
  });

  const navigate = useNavigate();

  const redux_dispatch = useDispatch();

  const id=useParams().id;
    useEffect(()=>{
        getOrgDataUser()
     },[])
     function getOrgDataUser(){
        var params = {
            org_id:id
          };
        Core.getOrgDataUser(
            getOrgDataUserSuccess,
            getOrgDataUsertFailed,
            ()=>{},
            params
        )
     }

     function updateProgress(status) {
      setLoading(status);
    }

     function getOrgDataUserSuccess(response){
        // console.log("response",response)
        setOrganisationData(response?.org_data.org_data)
        setOrgAddressData(response?.address_data)
     }
      function getOrgDataUsertFailed(errorList){
        toast.info(errorList.map((item) => item.m).join(", "));
      }
      // console.log("organisationData",organisationData)

      useEffect(() => {
        if(organisationData){
            setOrgName(organisationData.name || " ")
            setEmail(organisationData.email || " ")
            setPhoneNumber(organisationData.wa_number || " ")
            setRegisterDate(organisationData.date_added || " ")
            setRating(organisationData.ratings || " ")
            setOrgId(organisationData.org_id || " ")
            setPan(organisationData.pan_number || " ")
            setGst(organisationData.gst_number || " ")
            setCredits(organisationData.credits || " ")
            setBalance(organisationData.balance || " ")
        }
      }, [organisationData]);

      useEffect(()=>{
      if(orgAddressData){
        setPremise(orgAddressData.address_premise || " " )
        setStreet(orgAddressData.address_street || " " )
        setPinCode(orgAddressData.address_pincode|| " " )
        setCity(orgAddressData.address_city|| " " )
        
      }
      },[orgAddressData])

      function updateAddress(selectedList, selectedItem) {
        var value = [];
        selectedList.forEach((item) => {
          value.push(item.id);
        });
    
        setState(value);
      }
      function postUpdateUserOrg(progress_cb) {
        const params = {
          org_id: orgId,
          email:email,
          name: orgName,
          pan_number: pan,
          gst_number: gst,
          wa_number: phoneNumber,
          address_data: JSON.stringify({
            address_premise: premise,
            address_street: street,
            address_city: city,
            address_state: state,
            address_country: country,
            address_pincode: pinCode,
          }),
        };
        if (
          validateCompany(orgName) &&
          validateEmail(email) &&
          validatePremise(premise) &&
          validateStreet(street) &&
          validatePAN(pan) &&
          validateCity(city) &&
          validatePinCode(pinCode) &&
          validateGSTIN()
        ) {
          Core.postUpdateUserOrg(
            postUpdateUserOrgSuccess,
            postUpdateUserOrgFailed,
            progress_cb,
            params
          );
        }
      }
    
      function postUpdateUserOrgSuccess(response_data) {
        toast.success("Company details updated");
        getOrgDataUser();
        redux_dispatch({
          type: Actions.UPDATE_ORG_DATA,
          payload: {
            org_deep_data: {
              org_data: {
                pan_number: pan,
                gst_number: gst,
                name: orgName,
                wa_number: phoneNumber,
              },
            },
          },
        });
        setLoading(false);
      }
    
      function postUpdateUserOrgFailed(errorList) {
        toast.error(errorList.map((item) => item.m).join(", "));
      }
    
      function postVerifyEmailOrg() {
        const params = {};
    
        Core.postVerifyEmailOrg(
          postVerifyEmailOrgSuccess,
          postVerifyEmailOrgFailed,
          () => {},
          params
        );
        navigate("/email-verify-otp");
      }
    
      function validatePremise(value) {
        if (validator.isEmpty(value, {})) {
          set_error({ ...error, premise: "Please enter premise" });
        } else {
          set_error({ ...error, premise: "" });
          return true;
        }
      }
      function validateCompany(value) {
        if (!value && validator.isEmpty(value, {})) {
          set_error({ ...error, company: "Please enter company name" });
          return false;
        } else {
          set_error({ ...error, company: "" });
          return true;
        }
      }
      function postVerifyEmailOrgSuccess(response_data) {
        setLoading(true);
        toast.info("We sent an OTP on your Email, check inbox");
      }
    
      function postVerifyEmailOrgFailed(errorList) {
        toast.error(errorList.map((item) => item.m).join(", "));
        toast.error("OTP request failed");
      }
    
      function validatePAN() {
        if (!pan) {
          set_error({ ...error, pan: "Please enter PAN number" });
          return false;
        }
        if (pan.length !== 10 || !Utils.validatePan(pan)) {
          set_error({ ...error, pan: "Please enter a valid PAN" });
          return false;
        } else {
          set_error({ ...error, pan: "" });
          return true;
        }
      }

      function validateEmail(value) {
        if (!validator.isEmail(value, {})) {
          set_error({ ...error, email: "Please enter valid email" });
        } else {
          set_error({ ...error, email: "" });
          return true;
        }
      }
      function validateGSTIN() {
        if (gst.length > 0 && gst.length !== 15) {
          set_error({ ...error, gst: "Please enter a valid GST Number" });
          return false;
        } else if (gst.length > 0 && !Utils.validateGst(gst)) {
          set_error({ ...error, gst: "Please enter a valid GSTIN" });
        } else {
          set_error({ ...error, gst: "" });
          return true;
        }
    
        return true;
      }
      function validatePinCode(value) {
        if (validator.isEmpty(value, {})) {
          set_error({ ...error, pinCode: "Please enter PinCode" });
          return false;
        } else {
          set_error({ ...error, pinCode: "" });
          return true;
        }
      }
      function validateStreet(value) {
        if (validator.isEmpty(value, {})) {
          set_error({ ...error, street: "Please enter street" });
          return false;
        } else {
          set_error({ ...error, street: "" });
          return true;
        }
      }
      function validateCity(value) {
        if (validator.isEmpty(value, {})) {
          set_error({ ...error, city: "Please enter city name" });
    
          return false;
        } else {
          set_error({ ...error, city: "" });
          return true;
        }
      }

      if(!props.isActive){
        return <></>
    }
    
    return(
        <div className="organisation-detail-screen">
           <div className="organisation-detail-screen-r1">
            <div className="organisation-detail-screen-r1-e1">
              <div style={{marginBottom:"5px"}}>
                 <CustomText
                    label
                    text="Org Name"
                    fontSize={"font_size_large"}
                    fontWeight={"rubik_regular"}
                    textColor={"text_color_primary"}
                    textAlign={"text_align_left"}
                  />
              </div>
              <div style={{marginBottom:"5px"}}>
                  <CustomTextInput 
                    value={orgName}
                    placeholder={"Org Name"}
                    onChange={(e) => setOrgName(e.target.value)}
              />
              </div>
            </div>
            <div className="organisation-detail-screen-r1-e2">
                <div style={{marginBottom:"5px"}}>
                   <CustomText
                    label
                    text="Email"
                    fontSize={"font_size_large"}
                    fontWeight={"rubik_regular"}
                    textColor={"text_color_primary"}
                    textAlign={"text_align_left"}
                    />
                 </div>
            <div style={{marginBottom:"5px"}}>
                 <CustomTextInput
                  placeholder={"Email"}
                  onClickVerify={() => postVerifyEmailOrg()}
                  value={email}
                  // onBlur={(e) => validateEmail(e)}
                  onChange={(e) => setEmail(e.target.value)}/>
            </div>
            </div>
           </div>
           <div className="organisation-detail-screen-r1">
            <div className="organisation-detail-screen-r1-e1">
              <div style={{marginBottom:"5px"}}>
                 <CustomText
                    label
                    text="Phone Number(WhatsApp)"
                    fontSize={"font_size_large"}
                    fontWeight={"rubik_regular"}
                    textColor={"text_color_primary"}
                    textAlign={"text_align_left"}
                  />
              </div>
              <div style={{marginBottom:"5px"}}>
                  <CustomTextInput placeholder={"Phone Number"}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
              />
              </div>
            </div>
            <div className="organisation-detail-screen-r1-e2">
                <div style={{marginBottom:"5px"}}>
                   <CustomText
                    label
                    text="Register Date"
                    fontSize={"font_size_large"}
                    fontWeight={"rubik_regular"}
                    textColor={"text_color_primary"}
                    textAlign={"text_align_left"}
                    />
                 </div>
            <div style={{marginBottom:"5px"}}>
                 <CustomTextInput placeholder={"Register Date"}
                  value={Utils.convertUnixToDate(registerDate)}
                  onChange={(e) => setRegisterDate(e.target.value)}/>
            </div>
            </div>
           </div>
           <div className="organisation-detail-screen-r1">
            <div className="organisation-detail-screen-r1-e1">
              <div style={{marginBottom:"5px"}}>
                 <CustomText
                    label
                    text="Rating"
                    fontSize={"font_size_large"}
                    fontWeight={"rubik_regular"}
                    textColor={"text_color_primary"}
                    textAlign={"text_align_left"}
                  />
              </div>
              <div style={{marginBottom:"5px"}}>
                  <CustomTextInput placeholder={"Rating"}
                    value={rating}
                    onChange={(e) => setRating(e.target.value)}
              />
              </div>
            </div>
            <div className="organisation-detail-screen-r1-e2">
                <div style={{marginBottom:"5px"}}>
                   <CustomText
                    label
                    text="Org Id"
                    fontSize={"font_size_large"}
                    fontWeight={"rubik_regular"}
                    textColor={"text_color_primary"}
                    textAlign={"text_align_left"}
                    />
                 </div>
            <div style={{marginBottom:"5px"}}>
                 <CustomTextInput placeholder={"Org Id"} 
                 value={orgId}
                 onChange={(e) => setOrgId(e.target.value)}/>   
            </div>
            </div>
           </div>
           <div className="organisation-detail-screen-r1">
            <div className="organisation-detail-screen-r1-e1">
              <div style={{marginBottom:"5px"}}>
                 <CustomText
                    label
                    text="Pan Number"
                    fontSize={"font_size_large"}
                    fontWeight={"rubik_regular"}
                    textColor={"text_color_primary"}
                    textAlign={"text_align_left"}
                  />
              </div>
              <div style={{marginBottom:"5px"}}>
                  <CustomTextInput placeholder={"Pan Number"}
                    value={pan}
                    onChange={(e) => setPan(e.target.value)}
              />
              </div>
            </div>
            <div className="organisation-detail-screen-r1-e2">
                <div style={{marginBottom:"5px"}}>
                   <CustomText
                    label
                    text="GST Number"
                    fontSize={"font_size_large"}
                    fontWeight={"rubik_regular"}
                    textColor={"text_color_primary"}
                    textAlign={"text_align_left"}
                    />
                 </div>
                <div style={{marginBottom:"5px"}}>
                 <CustomTextInput placeholder={"Gst Number"} 
                 value={gst}
                 onChange={(e) => setGst(e.target.value)}
                 />
                </div>
            </div>
           </div>
           <div className="organisation-detail-screen-r1">
            <div className="organisation-detail-screen-r1-e1">
              <div style={{marginBottom:"5px"}}>
                 <CustomText
                    label
                    text="Premise"
                    fontSize={"font_size_large"}
                    fontWeight={"rubik_regular"}
                    textColor={"text_color_primary"}
                    textAlign={"text_align_left"}
                  />
              </div>
              <div style={{marginBottom:"5px"}}>
                  <CustomTextInput placeholder={"Premise"}
                    value={premise}
                    onChange={(e) => setPremise(e.target.value)}
                    onBlur={(e) => validatePremise(premise)}
              />
              </div>
            </div>
            <div className="organisation-detail-screen-r1-e2">
                <div style={{marginBottom:"5px"}}>
                   <CustomText
                    label
                    text="Street"
                    fontSize={"font_size_large"}
                    fontWeight={"rubik_regular"}
                    textColor={"text_color_primary"}
                    textAlign={"text_align_left"}
                    />
                 </div>
                 <div style={{marginBottom:"5px"}}>
                    <CustomTextInput placeholder={"Street"} 
                    value={street}
                    onBlur={(e) => validateStreet(street)}
                    onChange={(e) => setStreet(e.target.value)}/>
                 </div>
            </div>
           </div>
           <div className="organisation-detail-screen-r1">
            <div className="organisation-detail-screen-r1-e1">
              <div style={{marginBottom:"5px"}}>
                 <CustomText
                    label
                    text="City"
                    fontSize={"font_size_large"}
                    fontWeight={"rubik_regular"}
                    textColor={"text_color_primary"}
                    textAlign={"text_align_left"}
                  />
              </div>
              <div style={{marginBottom:"5px"}}>
                  <CustomTextInput placeholder={"City"}
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    onBlur={(e) => validateCity(city)}
              />
              </div>
            </div>
            <div className="organisation-detail-screen-r1-e2">
                <div style={{marginBottom:"5px"}}>
                   <CustomText
                    label
                    text="State"
                    fontSize={"font_size_large"}
                    fontWeight={"rubik_regular"}
                    textColor={"text_color_primary"}
                    textAlign={"text_align_left"}
                    />
                 </div>
            <div style={{marginBottom:"5px",width:"370px"}}>
            <div >
              <Multiselect
                selectedValues={Object.values(
                  Utils.getSelectedStateObjects([orgAddressData?.address_state])
                )}
                placeholder="Select State"
                id="css_custom"
                options={Object.values(CampaignData.LOCATIONS)}
                onSelect={(selectedList, selectedItem) =>
                  updateAddress(selectedList, selectedItem)
                }
                onRemove={(selectedList, selectedItem) =>
                  updateAddress(selectedList, selectedItem)
                }
                singleSelect={true}
                displayValue={"n"}
                showArrow={false}
                style={{
                  chips: Style.chipsAddress,
                  option: Style.option,
                  optionContainer: Style.optionContainer,
                  multiselectContainer: Style.multiselectContainerAddress,
                  searchBox: Style.searchBoxAddress,
                  inputField: Style.inputFieldAddress,
                }}
              />

              <h5 className="error">{error.state}</h5>
            </div>
            </div>
            </div>
           </div>
           <div className="organisation-detail-screen-r1">
            <div className="organisation-detail-screen-r1-e1">
              <div style={{marginBottom:"5px"}}>
                 <CustomText
                    label
                    text="Pin Code"
                    fontSize={"font_size_large"}
                    fontWeight={"rubik_regular"}
                    textColor={"text_color_primary"}
                    textAlign={"text_align_left"}
                  />
              </div>
              <div style={{marginBottom:"5px"}}>
                  <CustomTextInput placeholder={"Pin Code"}
                    value={pinCode}
                    onChange={(e) => setPinCode(e.target.value)}
                    onBlur={(e) => validatePinCode(pinCode)}
              />
              </div>
            </div>
            <div className="organisation-detail-screen-r1-e2">
                <div style={{marginBottom:"5px"}}>
                   <CustomText
                    label
                    text="Country"
                    fontSize={"font_size_large"}
                    fontWeight={"rubik_regular"}
                    textColor={"text_color_primary"}
                    textAlign={"text_align_left"}
                    />
                 </div>
                <div style={{marginBottom:"5px"}}> 
                 <CustomTextInput placeholder={"Country"} 
                 value={country}
                 onChange={(e) => setCountry(e.target.value)}/>
                </div>
            </div>
           </div>
           <div
          style={{
            flexDirection: "row",
            display: "flex",
            marginTop: 24,
            marginLeft:30,
            height: 90,
          }}>
          {" "}
          <div className="m-t-20" style={{ width: "33%", marginRight: 24 }}>
            <PrimaryCta
              fontSize={"font_size_large"}
              fullWidth100
              isLoading={isLoading}
              text={"Save Details"}
              // onClick={()=>alert("button clicked")}
              onClick={() => postUpdateUserOrg(updateProgress)}
              // disabled={isLoading ? true : false}
            />
          </div>
        </div>
        </div>
    )
}
export default OrganisationDetailsScreen;