"use-strict";

//Dependencies
import React, { useState, lazy, Suspense, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  CustomText,
  Image,
  CustomTextInput,
  PrimaryCta,
  LinkCta,
  UnderLinedLinkCta,
} from "../../components";

// Import Redux State (Private scope)
import { useDispatch, useSelector } from "react-redux";

import * as Actions from "../../redux/actions";

//import validator from 'validator'

// Core Sdk (Private Scope)
import Core from "../../common/clientSdk.js";

// Core Sdk (Private Scope)
import Utils from "../../common/utils.js";
import validator from "validator";
import WebStorage from "../../common/web_storage";
import { ORG_ID_KEY } from "../../common/web_storage_keys";
import { Loader } from "../loader";
import { useSnackbar } from "react-simple-snackbar";
import ReactGA from "react-ga";
import LeftSideSignup from "./leftSideSignup.js";

const LoginScreen = function (props) {
  const [loading, set_loading] = useState(false);
  const [email, set_email] = useState(null);
  const [password, set_password] = useState(null);
  //const [error, set_error] = useState(null);
  const [openSnackbar] = useSnackbar();

  var navigate = useNavigate();
  var redux_selector = useSelector((state) => {
    return state;
  });

  var redux_dispatch = useDispatch();

  // useEffect(() => {
  //   ReactGA.set({
  //     org_id: 123,
  //   });

  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  /********************************************************************
  Brand Login

  @param {Function} progress_cb - manage status of screen

  No return
  *********************************************************************/
  function adminLogin(progress_cb) {
    var params = {
      admin_data: JSON.stringify({
        username: email,
        password: password,
      }),
    };

    Core.adminLogin(adminLoginSuccess, adminLoginFailed, progress_cb, params);
  }

  /********************************************************************
  Brand Login success

  @param {Set} response_data - consisting user_data and brand_data

  No return
  *********************************************************************/
  function adminLoginSuccess(data) {
    console.log("Admin login", data);
    if (data) {
      window.sessionStorage.setItem("authenticated", "true");
      const user = {
        authToken: data.auth_id,
        ...data.login_data,
      };
      console.log("user data ", user);

      redux_dispatch({
        type: Actions.UPDATE_ADMIN_DATA,
        payload: user,
      });
    }
    //update brand user data in redux
    navigate("/");
  }

  /********************************************************************
  Brand Login Failed

  @param {string} error - consisting Error List

  No return
  *********************************************************************/
  function adminLoginFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
    //openSnackbar(errorList);
  }

  /********************************************************************
  Screen Progress

  @param {Boolean} status - true/false

  No return
  *********************************************************************/
  function updateProgress(status) {
    set_loading(status);
  }

  function googleAnalytics() {
    ReactGA.event({
      category: "User",
      action: "REELAX_ADS_WEB_LOGIN",
    });
  }

  // Return Screen

  return (
    <div className="main-container">
      <div className="content-container">
        <div className="auth-right-container">
          <div className="inner-left-container">
            <div className="inner-left-container-bind">
              <div className="flex-row  m-t-8">
                <div>
                  <CustomText
                    h5
                    className="h5text"
                    //fontSize={"font_size_25"}
                    text={`Login to Reelax`}
                    textColor={"text_color_primary"}
                    //fontWeight={"Poppins_regular_large"}
                  />
                </div>
                &nbsp;
              </div>
              <div className="m-t-16">
                <CustomText
                  h5
                  fontSize={"font_size_large"}
                  text={`Username`}
                  textColor={"text_grey_400_color"}
                  fontWeight={"Poppins_regular_large"}
                  height={"line_height_19_2"}
                />

                <div className="m-t-4 full-width">
                  <CustomTextInput
                    className="custom-input-text"
                    placeholder="Enter username"
                    onChange={(e) => set_email(e.target.value)}
                  />
                </div>
              </div>
              <div className="m-t-16">
                <CustomText
                  h5
                  fontSize={"font_size_large"}
                  text={`Password`}
                  textColor={"text_grey_400_color"}
                  fontWeight={"Poppins_regular_large"}
                  height={"line_height_19_2"}
                />

                <div className="m-t-4 full-width">
                  <CustomTextInput
                    className="custom-input-text"
                    type="password"
                    placeholder="Password"
                    onChange={(e) => set_password(e.target.value)}
                  />
                </div>
              </div>

              <div className="m-t-24 full-width">
                <PrimaryCta
                  fontSize={"font_size_large"}
                  fullWidth={true}
                  text={"Log In"}
                  onClick={() => adminLogin(updateProgress)}
                  isLoading={loading}
                  disabled={Utils.isNull(email) || Utils.isNull(password)}
                />
              </div>

              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: 40,
                }}
              >
                <UnderLinedLinkCta
                  fontSize={"font_size_small"}
                  text={"Terms of Service"}
                  textColor={"text_color_register"}
                  onClick={() => {
                    navigate("/terms");
                  }}
                />
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <CustomText
                    p
                    fontSize={"font_size_small"}
                    text={` and `}
                    textColor={"text_color_primary"}
                    fontWeight={"rubik_regular"}
                  />
                </div>

                <UnderLinedLinkCta
                  fontSize={"font_size_small"}
                  text={"Privacy Policy"}
                  textColor={"text_color_register"}
                  onClick={() => {
                    navigate("/privacy-policy");
                  }}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
