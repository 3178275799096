import React, { useState, useEffect } from "react";
import CustomTable from "../../components/customTable";
import Header from "../header";
import SideMenu from "../sideMenu";
import { useNavigate, useParams } from "react-router-dom";
import { UPDATE_ORG_DATA } from "../../redux/actions";
import Core from "../../common/clientSdk";
import { toast } from "react-toastify";
import Utils from "../../common/utils";
import { Loader } from "../loader";
import {
  CustomText,
  Image,
  PrimaryCta,
  ViewCards,
  ProposalsList,
} from "../../components";
import BrandListModal from "../../modal/brandListModal";

const Organisation = () => {
  const [organisationList, setOrganisationList] = useState([]);
  const [next, setNext] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [showBrandModal, setShowBrandModal] = useState(false);
  const [selectedOrgId, setSelectedOrgId] = useState(null);

  const module = useParams().module;
  console.log("module", module);

  const navigate = useNavigate();

  useEffect(() => {
    getOrgDataUser();
  }, []);

  function getOrgDataUser(progress_cb) {
    var params = {
      // org_id:"2gqcqb"
    };
    Core.getOrgListAdmin(
      getOrgDataUserSuccess,
      getOrgDataUsertFailed,
      (progress) => setLoading(progress),
      progress_cb,
      params
    );
  }
  function getOrgDataUserSuccess(response) {
    console.log("organisationList", organisationList);
    setOrganisationList(response.organization_list);
  }
  function getOrgDataUsertFailed(errorList) {
    toast.info(errorList.map((item) => item.m).join(", "));
  }

  function OrgClick(org_id) {
    if (module == "collection" || module == "create-campaign") {
      // navigate(`/brand-list/${org_id}`,{state:{module:module}})
      setSelectedOrgId(org_id);
      setShowBrandModal(true);
    } else {
      navigate(`/org_id/${org_id.org_id}`);
    }

    console.log("orgId", org_id);
    console.log("module", module);
  }

  function closeModal() {
    setShowBrandModal(false);
  }

  function OnClickHandlerBrand(brand_id) {
    if (module == "collection") {
      navigate(`/collection/${selectedOrgId}/${brand_id}`);
    }
  }

  console.log("SelectedOrgId", selectedOrgId);
  // console.log("organisationList",organisationList)
  const tableSchema = [
    {
      name: "Name",
      selector: (row) => row["name"],
      //   maxWidth: "121px",
      cell: (data) => (
        <span
          style={{ textAlign: "left" }}
          onClick={(e) => {
            OrgClick(data.org_id);
          }}
        >
          {data.name}
        </span>
      ),
    },
    {
      name: "Email",
      selector: (row) => row["email"],
    },
    {
      name: "Phone",
      selector: (row) => row["phone_number"],
      // maxWidth: "200px",
    },
    {
      name: "Credits",
      selector: (row) => row["credits"],
      maxWidth: "121px",
    },
    {
      name: "Wallet Amount",
      selector: (row) => row["balance"],
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "var(--general-secondary-color)", // Change header background color
        color: "var(--gray-gray-300)", // Change header text color
        fontSize: "16px",
        fontWeight: 600,
        lineHight: "19.2px",
        border: "none",
      },
    },
    headCells: {
      style: {
        padding: "10px 0px 10px 20px",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        fontSize: "16px",
        fontWeight: 400,
        lineHight: "19.2px",
        "&:hover": {
          backgroundColor: "var(--general-secondary-color)",
          fontWeight: 600,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "20px",
      },
    },
  };

  const tableData = organisationList.map((org_id) => ({
    brand_name: org_id.org_data.brand_name,
    name: org_id.org_data.name,
    email: org_id.org_data.email,
    phone_number: org_id.org_data.phone_number,
    credits: org_id.org_data.credits,
    is_verified: org_id.org_data.is_verified == 1 ? "Verified" : "Not Verified",
    org_id: org_id.org_data.org_id,
    balance: org_id.org_data.balance.toFixed(1),
  }));

  //  console.log("tableData3",tableData)

  return (
    <div className="dashboard-container">
      <SideMenu />
      <div className="main-screen">
        <Header />
        <div style={{ minHeight: 500 }}>
          {isLoading ? (
            <div className="loader-mid">
              <Loader />
            </div>
          ) : (
            <div className="page-container">
              <div className="campaign-text-heading">
                <CustomText
                  p
                  text={"Organisations"}
                  textColor={"text_color_dark"}
                  fontSize={"font_size_2xl"}
                />
              </div>
              <div className="custom-table">
                <CustomTable
                  className="CustomTable"
                  tableSchema={tableSchema}
                  paginationPerPage={10}
                  tableData={tableData}
                  rowPaddingTop={16}
                  rowPaddingBottom={16}
                  customStyles={customStyles}
                  onRowClicked={(item) => OrgClick(item)}
                  paginationTotalRows={organisationList?.length}
                  pagination
                />
              </div>
            </div>
          )}
          {showBrandModal && (
            <BrandListModal
              orgId={selectedOrgId}
              closeModal={closeModal}
              OnClickHandlerBrand={OnClickHandlerBrand}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default Organisation;
