import React, { useState, useEffect } from "react";
import OrgBrandListModal from "../../modal/orgBrandListModal";
import OrganisationList from "../../modal/orgListModal";
import BrandList from "../../modal/brandListModal";
import { useNavigate } from "react-router-dom";

function OrganisationListScreen({
  onSuccess,
  openOrgBrandListModal,
  closeOrgBrandListModal,
}) {
  const [orgId, setOrgId] = useState(null);
  const [loadModule, setLoadModule] = useState("org-list");

  console.log("loadModule", loadModule);

  const navigate = useNavigate();
  function onSuccessOrgList(org_id) {
    setOrgId(org_id);
    loadBrandList();
  }

  function clearOrgBrandListModal() {
    closeOrgBrandListModal();
  }

  function onSuccessBrandList(brand_id) {
    setLoadModule("org-list");
    onSuccess(brand_id, orgId);

    console.log("brand_id,orgId", brand_id, orgId);
  }

  function loadOrganisationList() {
    setLoadModule("org-list");
  }

  function loadBrandList() {
    setLoadModule("brand-list");
  }

  function determineTitle() {
    if (loadModule == "org-list") {
      return "Select Organisation";
    } else if (loadModule == "brand-list") {
      return "Select Brand";
    }
  }

  function renderModule() {
    if (loadModule == "org-list") {
      return <OrganisationList onSuccessOrgList={onSuccessOrgList} />;
    } else if (loadModule == "brand-list" && orgId) {
      return (
        <BrandList onSuccessBrandList={onSuccessBrandList} orgId={orgId} />
      );
    }
  }

  console.log("openOrgBrandListModal", openOrgBrandListModal);
  return (
    <OrgBrandListModal
      openOrgBrandListModal={openOrgBrandListModal}
      title={determineTitle()}
      loadOrganisationList={loadOrganisationList}
      closeOrgBrandListModal={clearOrgBrandListModal}
    >
      {renderModule()}
    </OrgBrandListModal>
  );
}

export default OrganisationListScreen;
