import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import Core from "../../common/clientSdk";
import SideMenu from "../sideMenu";
import Header from "../header";
import { Loader } from "../loader";
import { CustomText, Image, Video } from "../../components";
import Utils from "../../common/utils";
import { Rating } from "react-simple-star-rating";
import { Icon } from "@mui/material";
import { PieChart } from "react-minimal-pie-chart";
import campaign, * as CampaignData from "../../common/data/campaign";
import ReactHtmlParser from "react-html-parser";
import ContentAdminStatusScreen from "./adminContractStatus";

function ContractAdminDetails() {
  const [isLoading, setLoading] = useState(false);
  const [assignmentDetail, setAssignmentDetail] = useState({});
  const [influencerDetail, setInfluencerDetail] = useState({});
  const [campaignDetail, setCampaignDetail] = useState();
  const [categoryListData, setCategoryListData] = useState([]);
  const [followersCount, setFollowersCount] = useState("-");
  const [platformsData, setPlatformsData] = useState([]);
  const [contractData, setContractData] = useState();
  const [followersData, setFollowersData] = useState([]);
  const [ratingData, setRatingData] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  const assignment_id = useParams().id;
  console.log("ID ", assignment_id);

  useEffect(() => {
    assignmentAdminDetails(updateProgress);
  }, []);

  const [openSnackbar] = useSnackbar();

  function updateProgress(status) {
    setLoading(status);
  }
  function assignmentAdminDetails() {
    const params = {
      assignment_id: assignment_id,
    };
    Core.assignmentAdminDetails(
      assignmentAdminDetailsSuccess,
      assignmentAdminDetailsFailed,
      updateProgress,
      params
    );
  }

  function assignmentAdminDetailsSuccess(response_data) {
    setAssignmentDetail(response_data.assignment_deep_data || []);
    getInfluencerDetailAdmin(
      response_data.assignment_deep_data.influencer_data.influencer_id
    );
    campaginAdminDetails(
      response_data.assignment_deep_data.campaign_data.campaign_id
    );
  }
  function assignmentAdminDetailsFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }
  function campaginAdminDetails(campaign_id) {
    const params = {
      campaign_id: campaign_id,
    };
    Core.campaginAdminDetails(
      campaginAdminDetailsSuccess,
      campaginAdminDetailsFailed,
      updateProgress,
      params
    );
  }

  function campaginAdminDetailsSuccess(response_data) {
    // console.log("campagin_data", response_data);
    setCampaignDetail(response_data.campaign_data || []);
  }
  console.log("campagin data", campaignDetail);

  function campaginAdminDetailsFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  console.log("contract Data", assignmentDetail);

  function getInfluencerDetailAdmin(influencer_id) {
    var params = {
      influencer_id: influencer_id,
    };
    Core.getInfluencerAdminDetail(
      getInfluencerDetailAdminSuccess,
      getInfluencerDetailAdminFailed,
      updateProgress,
      params
    );
  }

  function getInfluencerDetailAdminSuccess(response) {
    setInfluencerDetail(response.influencer_deep_data || []);
    setPlatformsData(response.influencer_deep_data.platforms_data || []);
    setCategoryListData(
      Object.values(response.influencer_deep_data.categorys_data)
    );

    if (
      response.influencer_deep_data.platforms_data &&
      response.influencer_deep_data.platforms_data.length
    ) {
      let followerCount = 0;
      let newFollowersData = [];
      response.influencer_deep_data.platforms_data.forEach((platform) => {
        if (platform?.followers) {
          followerCount = followerCount + Number(platform?.followers);

          newFollowersData.push({
            title: Utils.socialMediaName(platform.platform_id),
            value: platform?.followers,
            color: Utils.socialMediaColor(platform.platform_id),
          });
        }
      });
      setFollowersCount(followerCount);
      setFollowersData(newFollowersData);
    }
  }
  console.log("influencerDetail", influencerDetail);

  // console.log("platform" , influencerDetail.platforms_data);

  function getInfluencerDetailAdminFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  const navigate = useNavigate();
  const handleClick = () => {
    setShowImage(!showImage);
  };

  const handleVideoClick = () => {
    setShowVideo(!showVideo);
  };

  //  --------- DP Showing Function -----------
  let influencerDp = influencerDetail?.influencer_data?.profile_picture
    ? Utils.generateInfluencerDpUrl(
        influencerDetail?.influencer_data?.influencer_id,
        influencerDetail?.influencer_data?.profile_picture
      )
    : require("../../resources/images/user-copy.png");

  // --------- Locations Showing Function -----------
  function getInfluencerLocation(influencer_data) {
    if (influencer_data["city_id"]) {
      return CampaignData.CITY.find(
        (city) => city.id == influencer_data["city_id"]
      )?.label;
    } else if (influencer_data["state"]) {
      return CampaignData.LOCATIONS[influencer_data["state"]]["n"];
    } else {
      return "-";
    }
  }

  function SampleContent(campaignDetail) {
    if (campaignDetail) {
      return (
        campaignDetail.sample_content_data &&
        campaignDetail.sample_content_data.map((item, index) => {
          if (item.sample_content_id.split(".").pop() === "mp4") {
            return (
              <div key={index} className="product-sample-main">
                <div
                  id={index}
                  // onClick={() => handleVideoClick()}
                  className="m-r-6"
                >
                  <video
                    controlsList="nodownload"
                    preload="metadata"
                    style={{ height: 106, width: 106 }}
                    src={Utils.generateCampaignSampleUrl(
                      campaignDetail.campaign_data.brand_id,
                      item.sample_content_id
                    )}
                  />
                </div>

                {showVideo && (
                  <div className="fullscreen-image">
                    <div style={{ position: "relative" }}>
                      <div
                        // onClick={() => handleVideoClick()}
                        className="fullscreen-image-close-btn"
                      >
                        <Icon iconName="CloseRoundButtonIcon" />
                      </div>
                      <Video
                        source={Utils.generateCampaignSampleUrl(
                          campaignDetail.campaign_data.brand_id,
                          item.sample_content_id
                        )}
                        width={600}
                        height={337}
                        type={"video/mp4"}
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          } else {
            return (
              <div key={index} className="product-sample-main">
                <div
                //  onClick={() => handleClick()}
                >
                  <Image
                    source={Utils.generateCampaignSampleUrl(
                      campaignDetail.campaign_data.brand_id,
                      item.sample_content_id
                    )}
                    width={106}
                    height={106}
                  />
                </div>

                {showImage && (
                  <div className="fullscreen-image">
                    <div style={{ position: "relative" }}>
                      <div
                        onClick={() => handleClick()}
                        className="fullscreen-image-close-btn"
                      >
                        <Icon iconName="CloseRoundButtonIcon" />
                      </div>
                      <Image
                        source={Utils.generateCampaignSampleUrl(
                          campaignDetail.campaign_data.brand_id,
                          item.sample_content_id
                        )}
                        width={600}
                        height={337}
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          }
        })
      );
    }
  }

  // ----------- influencer Screen --------------
  function influencerAdminDetails() {
    return (
      <>
        <div className="m-b-12">
          <CustomText
            p
            text={"Influencer Details"}
            textColor={"text_color_gray_500"}
            fontSize={"font_size_h7"}
            textAlign={"text_align_left"}
            fontWeight={"Inter_Bold"}
            lineHeight={"line_height_24"}
          />
        </div>
        <div className="influencer-detail-container">
          <div
            style={{ textAlign: "center" }}
            className="influencer_detail_container"
          >
            <Image
              fallBack={require("../../resources/images/user-copy.png")}
              source={influencerDp}
              onClick={
                () => ""
                // influencerDetailPage(
                //   influencerDetail?.influencer_data?.influencer_id
                // )
              }
              className="influencer-user-profile"
            />
            <div className="influencer-details-section">
              <CustomText
                h5
                text={influencerDetail?.influencer_data?.name}
                fontSize={"font_size_2xl"}
                textColor={"text_color_primary"}
                fontWeight={"Poppins_regular_large"}
              />
              <div className="flex-row">
                <Rating
                  initialValue={influencerDetail?.influencer_data?.ratings}
                  size={20}
                  readonly={true}
                />
                <div className="m-l-8">
                  <CustomText
                    p
                    text={influencerDetail?.influencer_data?.ratings.toFixed(1)}
                    fontSize={"font_size_small"}
                    textColor={"text_color_primary"}
                    fontWeight={"Inter_regular_small"}
                    height={"line_height_15_6"}
                  />
                </div>
              </div>

              <CustomText
                p
                text={influencerDetail?.influencer_data?.note}
                // noOfLine={"twoLines"}
                fontSize={"font_size_large"}
                textColor={"text_color_primary"}
                fontWeight={"Inter_regular_small"}
              />
            </div>
          </div>
          <div className="influencer_address_detail_container">
            {influencerDetail?.influencer_data?.state && (
              <div className="influencer_address_detail m-b-16">
                <CustomText
                  p
                  text={"Location"}
                  fontSize={"font_size_large"}
                  textColor={"text_color_primary"}
                  fontWeight={"rubik_regular"}
                  height={"line_height_19_2"}
                />
                <CustomText
                  p
                  text={
                    influencerDetail?.influencer_data &&
                    getInfluencerLocation(influencerDetail?.influencer_data)
                  }
                  className="Caption-3"
                  // fontSize={"font_size_large"}
                  textColor={"text_color_primary"}
                  // fontWeight={"Inter_semi_bold_500"}
                  // height={"line_height_19_2"}
                />
              </div>
            )}

            {influencerDetail?.influencer_data?.gender && (
              <div className="influencer_address_detail m-b-16">
                <CustomText
                  p
                  text={"Gender"}
                  fontSize={"font_size_large"}
                  textColor={"text_color_primary"}
                  fontWeight={"rubik_regular"}
                  height={"line_height_19_2"}
                />
                <CustomText
                  p
                  text={
                    influencerDetail?.influencer_data?.gender &&
                    Utils.capitalizeFirstLetter(
                      influencerDetail?.influencer_data?.gender
                    )
                  }
                  fontSize={"font_size_large"}
                  textColor={"text_color_primary"}
                  fontWeight={"Inter_Medium"}
                  height={"line_height_19_2"}
                />
              </div>
            )}

            {influencerDetail?.influencer_data?.language && (
              <div className="influencer_address_detail">
                <CustomText
                  p
                  text={"Languages"}
                  fontSize={"font_size_large"}
                  textColor={"text_color_primary"}
                  fontWeight={"rubik_regular"}
                  height={"line_height_19_2"}
                />
                <CustomText
                  p
                  text={
                    influencerDetail?.influencer_data?.language &&
                    Utils.capitalizeFirstLetter(
                      influencerDetail?.influencer_data?.language
                    )
                  }
                  fontSize={"font_size_large"}
                  textColor={"text_color_primary"}
                  fontWeight={"Inter_Medium"}
                  height={"line_height_19_2"}
                />
              </div>
            )}
          </div>
          <div className=" influencer-detail-category-platform">
            <div className="">
              <CustomText
                p
                text={"Category"}
                fontSize={"font_size_large"}
                textColor={"text_color_primary"}
                fontWeight={"Poppins_regular_large"}
              />
              <div className="category-background-influencer-container">
                {categoryListData &&
                  categoryListData.map((item, index) => {
                    return (
                      <>
                        {" "}
                        <div className="category-background-influencer">
                          <CustomText
                            p
                            text={item.name}
                            fontSize={"font_size_large"}
                            textColor={"text_color_primary"}
                            fontWeight={"rubik_regular"}
                          />
                        </div>{" "}
                        <div
                          style={{ color: "#8E8E93", fontSize: "large" }}
                          className="m-t-16"
                        >
                          {index < categoryListData.length - 1 ? "|" : ""}
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
          {/*  */}
          <div className="influencer_social_media_container">
            <div className="m-b-16">
              <CustomText
                p
                text={"Social Analytics"}
                fontSize={"font_size_large"}
                textColor={"text_color_primary"}
                fontWeight={"Poppins_regular_large"}
              />
            </div>
            <div className="social_media_chart_icon">
              <div style={{ height: 143 }}>
                {followersData && followersData.length > 0 ? (
                  <PieChart
                    lineWidth={25}
                    paddingAngle={2}
                    totalValue={followersCount}
                    data={followersData}
                    label={({ dataEntry }) => dataEntry.title}
                    labelStyle={(index) => ({
                      fill: followersData[index].color,
                      fontSize: "5px",
                      fontFamily: "sans-serif",
                    })}
                  />
                ) : (
                  <div className="m-b-16">
                    <CustomText
                      p
                      text={"No Social Analytics Available"}
                      fontSize={"font_size_large"}
                      textColor={"text_color_primary"}
                      fontWeight={"Poppins_regular_large"}
                    />
                  </div>
                )}
              </div>
              <div className="m-l-24">
                <div className="influencer_social_media_id_icon_container">
                  {platformsData.map((item) => {
                    function openUrl() {
                      window.open(
                        Utils.getSocialUrl(
                          item.platform_id,
                          item.identifier_id
                        ),
                        "_blank"
                      );
                    }

                    return (
                      <div className="influencer_social_media_id m-b-16">
                        <div
                          onClick={() => openUrl()}
                          className="influencer_social_media_id_icons"
                        >
                          <Icon
                            iconName={Utils.socialMediaIcons(item.platform_id)}
                            height={40}
                            width={40}
                          />
                          <div>
                            <CustomText
                              p
                              text={Utils.socialMediaName(item.platform_id)}
                              fontSize={"font_size_small"}
                              textColor={"text_color_primary"}
                              fontWeight={"rubik_regular"}
                            />
                            <CustomText
                              p
                              text={item.identifier_id}
                              fontSize={"font_size_extraSmall"}
                              textColor={"text_color_primary"}
                              fontWeight={"rubik_regular"}
                            />
                          </div>
                        </div>
                        <div>
                          <CustomText
                            p
                            text={
                              !Utils.isUndefined(item.followers)
                                ? Utils.changeNumberFormate(item.followers)
                                : "0"
                            }
                            fontSize={"font_size_extraSmall"}
                            textColor={"text_color_primary"}
                            fontWeight={"rubik_regular"}
                          />
                          <CustomText
                            p
                            text={
                              !Utils.isUndefined(item.following)
                                ? Utils.changeNumberFormate(item.following)
                                : "0"
                            }
                            fontSize={"font_size_extraSmall"}
                            textColor={"text_color_primary"}
                            fontWeight={"rubik_regular"}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  // ----------- Content Screen --------------

  function ContentDetailsScreen() {
    return (
      <>
        <div className="m-b-12">
          <CustomText
            p
            text={"Contract Details"}
            textColor={"text_color_gray_500"}
            fontSize={"font_size_h7"}
            textAlign={"text_align_left"}
            fontWeight={"Inter_Bold"}
            lineHeight={"line_height_24"}
          />
        </div>
        <div class="contract-details-container">
          <div className="contract-details-container-row">
            <div className="contract-details-container-col-1">
              <CustomText
                p
                text={"Campaign Name"}
                textColor={"text_color_gray_500"}
                className="Body-1"
              />
            </div>
            <div className="contract-details-container-col-2">
              <CustomText
                p
                text={assignmentDetail?.campaign_data?.name}
                textColor={"text_color_gray_500"}
                className="Caption-3"
              />
            </div>
          </div>
          <div className="seperater" />
          <div className="contract-details-container-row">
            <div className="contract-details-container-col-1">
              <CustomText
                p
                text={"Status"}
                textColor={"text_color_gray_500"}
                className="Body-1"
              />
            </div>

            <div className="contract-details-container-col-2">
              <CustomText
                p
                text={Utils.assignmentStatusToString(
                  assignmentDetail?.assignment_data?.assignment_status
                )}
                className="Caption-3"
              />
            </div>
          </div>
          <div className="seperater" />
          <div className="contract-details-container-row">
            <div className="contract-details-container-col-1">
              <CustomText
                p
                text={"Contract ID"}
                textColor={"text_color_gray_500"}
                className="Body-1"
              />
            </div>
            <div className="contract-details-container-col-2">
              <CustomText
                p
                text={assignmentDetail?.assignment_data?.assignment_id}
                textColor={"text_color_gray_500"}
                className="Caption-3"
              />
            </div>
          </div>

          <div className="seperater" />
          <div className="contract-details-container-row">
            <div className="contract-details-container-col-1">
              <CustomText
                p
                text={"Delivery Date"}
                textColor={"text_color_gray_500"}
                className="Body-1"
              />
            </div>
            <div className="contract-details-container-col-2">
              <CustomText
                p
                text={Utils.formatDate(
                  assignmentDetail?.assignment_data?.delivery_date
                )}
                textColor={"text_color_gray_500"}
                className="Caption-3"
              />
            </div>
          </div>
          <div className="seperater" />
          <div className="contract-details-container-row">
            <div className="contract-details-container-col-1">
              <CustomText
                p
                text={"Reward Amount"}
                textColor={"text_color_gray_500"}
                className="Body-1"
              />
            </div>
            <div className="contract-details-container-col-2">
              <CustomText
                p
                text={
                  assignmentDetail?.reward_data?.value?.amount == 0
                    ? "-"
                    : `₹` + assignmentDetail?.reward_data?.value?.amount
                }
                textColor={"text_color_gray_500"}
                className="Caption-3"
              />
            </div>
          </div>
          <div className="seperater" />
          <div className="contract-details-container-row">
            <div className="contract-details-container-col-1">
              <CustomText
                p
                text={"Content Type"}
                textColor={"text_color_gray_500"}
                className="Body-1"
              />
            </div>
            <div className="contract-details-container-col-2">
              <CustomText
                p
                text={Utils.getCampaignType(
                  assignmentDetail?.campaign_data?.type
                )}
                textColor={"text_color_gray_500"}
                className="Caption-3"
              />
            </div>
          </div>
          {campaignDetail?.instruction_data?.instruction_note && (
            <>
              <div className="seperater" />
              <div className="contract-details-container-row">
                <div className="contract-details-container-col-1">
                  <CustomText
                    p
                    text={"Instructions"}
                    textColor={"text_color_gray_500"}
                    className="Body-1"
                  />
                </div>
                <div className="contract-details-container-col-2">
                  <div
                    className="Caption-3 "
                    style={{ color: "var(--gray-gray-500)" }}
                  >
                    {ReactHtmlParser(
                      campaignDetail.instruction_data.instruction_note
                    )}
                  </div>
                </div>
              </div>
            </>
          )}

          {campaignDetail?.sample_content_data.length !== 0 && (
            <>
              <div className="seperater" />
              <div className="contract-details-container-row">
                <div className="contract-details-container-col-1">
                  <CustomText
                    p
                    text={"Sample"}
                    textColor={"text_color_gray_500"}
                    className="Body-1"
                  />
                </div>
                <div
                  className="contract-details-container-col-2"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  {SampleContent(campaignDetail)}
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }

  //  --------- ContentStatusScreen -------------

  // function ContentStatusScreen (){}

  return (
    <div className="dashboard-container">
      <SideMenu />
      <div className="main-screen">
        <Header />
        <div className="page-container-campaignList">
          {isLoading ? (
            <div className="loader-mid">
              <Loader />
            </div>
          ) : (
            <>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flex: 1,
                  }}
                >
                  <div
                    style={{ marginLeft: "20px" }}
                    className="contract-details-box"
                  >
                    {influencerAdminDetails()}
                  </div>

                  <div className="contract-details-box">
                    {ContentDetailsScreen()}
                  </div>

                  <div className="contract-details-box">
                    {
                      <ContentAdminStatusScreen
                        assignmentAdminDetails={assignmentAdminDetails}
                      />
                    }
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ContractAdminDetails;
