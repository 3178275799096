const baseUrl = process.env.REACT_APP_API_BASE_URL;
let mediaUrl = process.env.REACT_APP_MEDIA_BASE_URL;
var Config = {
  // http request constants
  // BASE_URL: baseUrl,

  BASE_URL: baseUrl,
  MEDIA_BASE_URL: mediaUrl,

  // Common constants
  GET_METHOD: "GET",
  POST_METHOD: "POST",
  JSON_REQUEST: "json",

  //Error codes
  SERVER_ERROR: "err_internal_server_error",

  // End Points
  API_ENDPOINTS: {
    API_URN_BRAND_SIGNUP: "/user/user/signup",
    API_URN_BRAND_LOGIN: "/user/user/login",
    API_URN_ADMIN_LOGOUT: "/admin/admin/logout",
    API_URN_ADMIN_SELF_DATA: "/admin/admin/details",
    API_URN_CREATE_BRAND: "/brand/user/create",
    API_URN_GENEREATE_UPLOAD_URL: "/brand/generate-upload-url-user",
    API_URN_UPDATE_BRAND_LOGO: "/brand/update-brand-logo",
    API_URN_GET_BRAND_LIST: "/brand/user/list",
    API_URN_GET_BRAND: "/brand/user/details-self",
    API_URN_UPDATE_BRAND: "/brand/update-brand",
    API_URN_CREATE_CAMPAIGN_USER: "/campaign/user/create",
    API_URN_UPLOAD_MEDIA_URL: "/user/generate-url-user",
    API_URN_GET_CAMPAIGN_LIST_USER: "/campaign/admin/list",
    API_URN_ADMIN_CAMPAIGN_PUBLISH: "/campaign/admin/publish",
    API_URN_POST_OFFLINE_ONLINE_CAMPGAIGNS: "/campaign/admin/offline-campaign",
    API_URN_GET_BRAND_REPORT: "/report/user/brand",
    API_URN_GET_CAMPAIGN_USER: "/campaign/get-campaign-user",
    API_URN_USER_LOGOUT_SELF: "/user/logout-self",
    // API_URN_GET_CAMPAIGN_BIDS_USER: "/bid/get-bid-campaign-user",
    API_URN_GET_CAMPAIGN_BIDS_USER: "/bid/admin/list",
    API_URN_GET_CATEGORY: "/category/user/list",
    API_URN_GET_CAMPAIGN_CONTENT: "/campaign/get-content-user",
    API_URN_POST_REJECT_BID_USER: "/bid/reject-user",
    API_URN_POST_APPROVE_BID_USER: "/bid/approve-user",
    API_URN_POST_SHORTLIST_BID_USER: "/bid/shortlist-user",
    API_URN_GET_ORDER_LIST_USER: "/order/get-order-list-user",

    API_URN_GET_CAMPAIGN_USER_DETAIL: "/campaign/admin/details",
    API_URN_GET_BID_INFLUENCER_LIST: "/bid/influencer/list",
    API_URN_ASSIGNMENT_USER: "/assignment/admin/list",
    API_URN_BID_LIST: "/bid/admin/list",
    API_URN_APPROVE_BID_USER: "/bid/user/approve",
    API_URN_TRANSACTION_USER: "/order/user/list",
    API_URN_ORG_DATA_USER: "/org/admin/details",
    API_URN_ASSIGNMENT_USER_DETAIL: "/assignment/user/details",
    API_URN_ASSIGNMENT_REVIEW_CONTENT: "/assignment/review-content-admin",
    API_URN_INFLUENCER_DETAIL_PUBLIC: "/influencer/admin/details",
    API_URN_GET_MEMBER_LIST: "/user/user/list",
    API_URN_POST_INVITE_MEMBER: "/user/invite-members",
    API_URN_GET_TRANSACTION_HISTORY: "/order/user/list",
    API_URN_GET_MESSAGE_LIST: "/message/user/list",
    API_URN_POST_SEND_MESSAGE: "/message/user/send",
    API_URN_POST_DELETE_MESSAGE: "message/user/delete",
    API_URN_POST_UPDATE_CAMPAIGN: "/campaign/user/update",
    API_URN_GET_CREDIT_LIST: "/credit/user/list",
    API_URN_GET_CREDIT_DETAIL: "/user/get-credit-details-user",
    API_URN_POST_CREDIT_ORDER: "/order/user/create-credit",
    API_URN_POST_FORGOT_PASSWORD: "/user/forgot-password-user",
    API_URN_POST_VERIFY_FORGOT_PASSWORD: "/user/verify-forgot-password-user",
    API_URN_GET_ORG_KYC_DETAILS: "/org/user/kyc-details",
    API_URN_POST_UPDATE_ORG: "/org/admin/update",
    API_URN_POST_VERIFY_EMAIL: "/org/verify-email",
    API_URN_POST_REQUEST_EMAIL_VERIFICATION: "/org/request-email-verification",
    API_URN_POST_ORG_ADD_KYC: "/org/user/add-kyc",
    API_URN_GENERATE_UPLOAD_MEDIA_URL: "/user/generate-url-user",
    API_URN_GET_ORG_DETAIL: "/org/user/details",
    API_URN_CREATE_ORDER_ASSIGNMENT: "/order/create-order-assignment",
    API_URN_CHANGE_PASSWORD: "/user/change-password",
    API_URN_UPDATE_USER_DATA: "/user/user/update",
    API_URN_ASSIGNMENT_BRAND: "/assignment/get-brand-assignment-list-user",
    API_URN_GET_REPORT_CAMPAIGN: "/report/admin/campaign",
    API_URN_UPDATE_BRAND_SETTING: "/brand/user/update",
    API_URN_PAYOUT_LIST: "/payouts/user/list",
    API_URN_POST_RATTING_INFLUENCER: "/user/rate-influencer",
    API_URN_GET_CREDIT_TRANSACTION: "/credit/admin/credit-list",
    API_URN_PUBLISHED_CAMPAIGN_USER: "/campaign/publish-campaign-user",
    API_URN_ACTIVE_INACTIVE_CAMPAIGN_ADMIN:
      "/campaign/admin/mark-campaign-active",
    API_URN_SAVE_CAMPAIGN_USER: "/campaign/save-campaign-user",
    API_URN_POST_REJECT_BID_USER: "/bid/user/reject",
    API_URN_INFLUENCER_PLATFORM_INSIGHT:
      "/platform/get-influencer-platform-insight",
    API_URN_INVITE_INFLUENCER_LIST: "/campaign/admin/invite-list",
    API_URN_POST_INVITE_INFLUENCER: "/campaign/user/invite",

    API_URN_ASSIGNMENT_USER_WITHDRAW: "/assignment/user/withdraw",

    API_URN_GET_INFLUENCER_LIST: "/campaign/admin/influencer-list",
    API_URN_CREATE_WALLET: "/order/user/create-wallet",
    API_URN_BID_USER_CREATE_COUNTER: "/bid/user/create-counter",
    API_URN_POST_INFLUENCER_LIST_WITHDRAW:
      "/campaign/influencer/invited-campaigns",
    API_URN_ADMIN_NOTIFICATION: "/notification/user/create",
    API_URN_NOTIFICATION_USER_DETAIL: "/notification/user/details",
    API_URN_INFLUENCER_PORTFOLIO: "/portfolio/user/list-portfolio",
    API_URN_ASSIGNMENT_CONTENT_LIST: "/assignment/admin/list-content",
    API_URN_ASSIGNMENT_CONTENT_BY_CONTENT_ID:
      "/assignment/user/get-content-by-content-id",
    API_URN_CAMPAIGN_ANALYTICS: "/campaign/admin/campaign-analytics",
    API_URN_CAMPAIGN_CONTENT_ANALYTICS:
      "/campaign/admin/campaign-contents-analytics",
    API_URN_ADD_COLLECTION: "/collection/admin/add-collection",
    API_URN_COLLECTION_LIST: "/collection/admin/collection-list",
    API_URN_ADD_INFLUENCER_COLLECTION:
      "/collection/admin/add-influencer-collection",
    API_URN_INFLUENCER_COLLECTION_LIST:
      "/collection/admin/collection-influencer-list",
    API_URN_INFLUENCER_COLLECTION_DELETE:
      "/collection/admin/collection-influencer-delete",
    API_URN_COLLECTION_DELETE: "/collection/user/collection-delete",
    API_URN_INFLUENCER_DISCOVERY: "/admin/influencer-discovery-admin",
    API_URN_PLATFORM_EFFECTIVE_FOLLOWERS: "/platform/get-effective-followers",

    API_URN_COLLECTION_GENERATE_SHARE_KEY:
      "/collection/user/generate-share-key",
    API_URN_COLLECTION_ADD_PRICE_INFLUENCER:
      "/collection/user/add-price-collection-influencer",
    API_URN_COLLECTION_INFLUENCER_LIST_BY_SHARE_KEY:
      "/collection/public/influencer-list-by-share-key",
    API_URN_INFLUENCER_CONTRACTS_LIST_BY_SHARE_KEY:
      "/assignment/public/assignment-list-by-sharekey",
    API_URN_UPDATE_COLLECTION_REVIEW_STATUS:
      "/collection/public/update-collection-influencer-review-status",

    API_URN_GENERATE_CONTRACTS_SHARE_LIST_KEY:
      "/assignment/user/generate-sharekey-assignment",

    API_URN_SUBSCRIPTION_USER_DETAIL: "/subscription/user/details",
    API_URN_ADMIN_LOGIN: "/admin/admin/login",
    API_URN_ADMIN__GET_DATA_SELF: "/admin/admin/details",
    API_URN_ADMIN_REPORT: "/report/admin/all",
    API_URN_ADMIN_VERIFY: "/admin/admin/verify-kyc",
    API_URN_GET_PLATFORM: "/platform/sync-platform-admin",
    API_URN_ADMIN_PAYOUT_LIST: "/payouts/admin/list",
    API_URN_INFLUENCER_BAN: "/admin/admin/ban-influencer",
    API_URN_INFLUENCER_DETAIL_UPDATE: "/admin/update-influencer-data-admin",
    API_URN_ASSIGNMENT_ADMIN_DETAILS: "/assignment/admin/details",
    API_URN_INFLUENCER_ADMIN_DETAILS: "/influencer/admin/details",
    API_URN_PAYOUT_ADMIN_DETAILS: "/payouts/admin/details",
    API_URN_PAYOUT_INITIATE_TRANSFER: "/payouts/admin/initiate-transfer",
    API_URN_ADMIN_CAMPAIGN_DETAIL: "/campaign/admin/details",
    API_URN_ADMIN_CONTRACT_LIST: "/assignment/admin/list",
    API_URN_ADMIN_NOTIFICATION_SEND: "/notification/admin/send",
    API_URN_ADMIN_KYC_LIST: "/admin/admin/kyc-list",
    API_URN_ADMIN_KYC_DETAIL: "/admin/admin/kyc-details",
    API_URN_POST_BOSAST_CAMPGAIGNS: "/campaign/admin/boost",
    API_URN_POST_OFFLINE_ONLINE_CAMPGAIGNS: "/campaign/admin/offline-campaign",
    API_URN_ADMIN_ORG_LIST: "/org/admin/list",
    API_URN_ADMIN_TRANS_LIST: "/order/admin/list",
    API_URN_ADMIN_BRAND_LIST: "/brand/admin/list",
    API_URN_CREATE_CREDIT_ADMIN: "/admin/admin/add-credit",
    API_URN_MARK_COMPLETE: "/campaign/admin/mark-campaign-complete",
    API_URN_CANCEL_CONTRACT: "/assignment/admin/withdraw",
    API_URN_POST_BANK_DETAILS: "/influencer/admin/remove-bank-details",

    API_URN_POST_ASSIGNMENT_CREATE: "/assignment/admin/create",
    API_URN_POST_PAYOUTS_MARK_COMPLETED: "/payouts/admin/mark-payout-complete",

    API_URN_ADD_INFLUENCER: "/influencer/admin/add-influencer-admin",
    API_URN_POST_MARK_CAMPAIGN_FREE: "/campaign/admin/mark-campaign-free",
    API_URN_POST_COLLECTION_UPDATE: "/collection/user/update-collection",
  },
};

export default Config;
