import React, { useState, useEffect } from "react";
import {
  CustomText,
  FallBack,
  AssignmentList,
  FilterBtn,
  // CampaignListDropdown
  // InfluencerDropdown,
  InfluencerListDropdown,
  PrimaryCta,
} from "../../components";
import { useSnackbar } from "react-simple-snackbar";
import InfiniteScroll from "react-infinite-scroll-component";
import Core from "../../common/clientSdk";
import { Loader } from "../loader";
import Header from "../header";
import SideMenu from "../sideMenu";
import utils from "../../common/utils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomTable from "../../components/customTable";
import ReportSidebar from "../report-sidebar";
import exportToCSV from "../../common/exportToCSV";
import { useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const limit = 10;
const campaignStatusList = [
  { label: "ALL", id: "ALL" },
  { label: "ACTIVE", id: "ACTIVE" },
  { label: "COMPLETED", id: "COMPLETED" },
  { label: "INACTIVE", id: "INACTIVE" },
  { label: "IN PROGRESS", id: "IN_PROGRESS" },
];

function AdminContractListScreen() {
  const query = useQuery();
  const [searchParams] = useSearchParams();
  const [assignmentList, setAssignmentList] = useState([]);
  const [filterStatus, setFilterStatus] = useState("ALL");
  const [loading, setLoading] = useState(true);
  const [start, setStart] = useState(0);
  const [next, setNext] = useState(10);

  const [filteredData, setFilteredData] = useState([]);
  const [openSnackbar] = useSnackbar();
  const navigate = useNavigate();
  const [statusColors] = useState({
    ACTIVE: "#FABC13",
    INACTIVE: "red",
    COMPLETED: "#009A29",
    IN_REVIEW: "#1977f2",
    PAYMENT_PENDING: "#ff4500",
    CENCELLED: "#ff4500",
    IN_PROGRESS: "#ff4500",
  });

  useEffect(() => {
    getAssignmentAdminDetails(updateProgress);

    const label = searchParams.get("label");
    console.log("params 2", label);
    const params = new URLSearchParams();
    if (filterStatus !== "ALL") {
      params.set("label", filterStatus);
    }
    navigate({ search: params.toString() });
    searchParams.get("label");

    console.log("params 3", params.toString());
  }, [filterStatus]);

  function updateProgress(status) {
    setLoading(status);
  }

  const redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  // const campaign_id =  campaign_id;

  function navigateToAssignmentDetails(assignmentId) {
    navigate(`/contractDetail/${assignmentId}`);
  }

  function getAssignmentAdminDetails() {
    var params = {
      // campaign_id: campaign_id
      // brand_id: redux_selector.BrandUser.brand_deep_data.brand_data.brand_id,
    };
    if (filterStatus != "ALL") {
      params.status = filterStatus;
    }
    Core.getAssignmentAdminDetails(
      getAssignmentAdminDetailsSuccess,
      getAssignmentAdminDetailsFailed,
      updateProgress,
      params
    );
  }

  function getAssignmentAdminDetailsSuccess(response_data) {
    setAssignmentList(response_data["assignment_list"]);
    setNext(response_data?.pagination_data?.next);
    setLoading(false);
  }

  function getAssignmentAdminDetailsFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  const AssignmentCSV = () => {
    const data = assignmentList.map((item) => {
      return {
        name: item.influencer_data.name,
        reward: item.reward_data.value.amount,
      };
    });

    return (
      <div>
        <PrimaryCta
          sixth
          textColor={"text_color_primary"}
          iconName={"downloadIcon"}
          fontSize={"font_size_large"}
          className="transaction-csv-btn"
          text={"Export to CSV"}
          onClick={() => exportToCSV(data)}
        />
      </div>
    );
  };

  // const handlePageChange = (page) => {
  //   getAssignmentBrandDetails(page);
  // };
  // Table Schema
  const tableSchema = [
    {
      name: "Influencer Name",
      selector: (row) => {
        // console.log('row',row.influencer_data.name)
        return row["influencer_data"]["name"];
      },

      //  sortable: true,
      // cell: (data) =>  (
      //   <span
      //     // onClick={(e) => {
      //     //   CampaignClick(data.campaign_data.campaign_id);
      //     //   e.stopPropagation();
      //     // }}
      //   >
      //     {/* {utils.truncateWithEllipsis(data.campaign_data.name, 60)} */}
      //
      //   </span>
      // ),
    },

    {
      name: "Rewards",
      maxWidth: "160px",
      selector: (row) => {
        // console.log('amount',row.reward_data.amount)

        return row["reward_data"]["value"]["amount"] == 0
          ? "-"
          : "Rs " + row["reward_data"]["value"]["amount"];
      },

      // maxWidth: "121px",

      // sortable: true,
      // cell: (data) => console.log('data',data) (
      //   <span
      //     // onClick={(e) => {
      //     //   CampaignClick(data.campaign_data.campaign_id);
      //     //   e.stopPropagation();
      //     // }}
      //   >
      //     {/* {utils.truncateWithEllipsis(data.campaign_data.name, 60)} */}
      //     {
      //       data?.reward_data?.value
      //     }
      //   </span>
      // ),
    },

    {
      name: "Deliverables",
      selector: (row) => {
        return utils.generateAssignmentSubText(row);
      },
      // // sortable: true,
      // maxWidth: "121px",
      maxWidth: "500px",
    },
    {
      name: "Delivery Date",
      selector: (row) => {
        return utils.convertUnixToDate(row?.campaign_data?.delivery_date);
      },
      // // sortable: true,
      // maxWidth: "121px",
      maxWidth: "140px",
    },

    {
      name: "Status",

      selector: (row) => {
        return utils.assignmentStatusToString(
          row["assignment_data"]["assignment_status"]
        );
      },

      maxWidth: "185px",
      cell: (data) => (
        <span
          style={{
            color: statusColors[data.assignment_data.assignment_status],
          }}
          // onClick={(e) => {
          //   CampaignClick(data.campaign_data.campaign_id);
          //   e.stopPropagation();
          // }}
        >
          {utils.assignmentStatusToString(
            data.assignment_data.assignment_status
          )}
        </span>
      ),
    },
  ];

  // TABLE CSS

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "var(--general-secondary-color)", // Change header background color
        color: "var(--gray-gray-300)", // Change header text color
        fontSize: "16px",
        fontWeight: 600,
        lineHight: "19.2px",
        border: "none",
      },
    },
    headCells: {
      style: {
        padding: "10px 0px 10px 20px",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        fontSize: "16px",
        fontWeight: 400,
        lineHight: "19.2px",
        "&:hover": {
          backgroundColor: "var(--general-secondary-color)",
          fontWeight: 600,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "20px",
      },
    },
  };

  return (
    <div className="dashboard-container">
      <SideMenu />
      <div className="main-screen">
        <Header />

        <div className="page-container-campaignList">
          <div className="campaignlist-header m-t-30">
            <InfluencerListDropdown
              dropdownItems={campaignStatusList}
              onItemClick={(item) => {
                setNext(0);
                setFilterStatus(item);
              }}
            />
          </div>
          {loading ? (
            <div className="loader-mid">
              <Loader />
            </div>
          ) : (
            <div className="custom-table">
              <CustomTable
                className="CustomTable"
                tableSchema={tableSchema}
                tableData={assignmentList}
                rowPaddingTop={16}
                paginationPerPage={15}
                rowPaddingBottom={16}
                customStyles={customStyles}
                onRowClicked={(item) =>
                  navigateToAssignmentDetails(
                    item.assignment_data.assignment_id
                  )
                }
                pagination
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminContractListScreen;
