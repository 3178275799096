import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { CustomText, PrimaryCta, TeamMembers } from "../components";
import Core from "../common/clientSdk";
import { Loader } from "../screens/loader";
import { useNavigate } from "react-router-dom";
import Icon from "../components/core_component/icon";
import { useSnackbar } from "react-simple-snackbar";
import toast from "react-hot-toast";

const BrandList=(props)=>{
    const [isLoading, setLoading] = useState(false);
    const [brandList,setBrandList]=useState([]);

    useEffect(()=>{
        getBrandListAdmin()
    },[])
    function getBrandListAdmin(){
        var params = {
            org_id:props.orgId
          };
        Core.getBrandListAdmin(
          getBrandListAdminSuccess,
          getBrandListAdminFailed,
            (status)=>setLoading(status),
            params
        )
      }
      function getBrandListAdminSuccess(response){
        setBrandList(response.brand_list)
        console.log("Brandresponse",response)
      }
    
      console.log("brandList",brandList)
      console.log("orgId",props.orgId)
      
      function getBrandListAdminFailed(errorList){
        toast.info(errorList.map((item) => item.m).join(", "));
      }
      const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          background: "var(--general-secondary-color)",
          borderColor: "var( --blue-blue-500)",
          textAlign: "center",
          zIndex: "5",
          height: "80%",
          display: "flex",
          flexDirection: "column",
          // justifyContent: "space-around",
          minWidth: "400px",
        },
        overlay: {
          background: "rgba(0, 0, 0, 0.86)",
          zIndex: 10,
        },
      };
    return(
        <>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="brand-list-height">
              {brandList &&
                brandList.map((item) => {
                  return (
                    <div style={{cursor:"pointer",display:"flex",flexDirection:"column", marginBottom:"10px"}}
                      onClick={() => {
                        props.onSuccessBrandList(item.brand_data.brand_id);
                      }}
                      >
                        <div>
                        {item.brand_data.name}
                        </div>
                        <div>
                        {`#${item.brand_data.brand_id}`}
                        </div>
                      </div>
                  );
                })}
            </div>
          </>
        )}
        </>
    )
}
export default BrandList;