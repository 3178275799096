import React, { useEffect, useState } from "react";
import Core from "../../common/clientSdk";
import { useSnackbar } from "react-simple-snackbar";
import SideMenu from "../sideMenu";
import { Loader } from "../loader";
import {
  CustomText,
  CustomTextArea,
  CustomTextInput,
  PrimaryCta,
} from "../../components";
import Select from "react-dropdown-select";
import { ModalComponent } from "../../components";
import Header from "../header";

function NotificationPage() {
  const [isLoading, setLoading] = useState(false);
  const [effectiveLoad, setEffectiveLoad] = useState(false);
  const [type, setType] = useState("influencer");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const [typeError, setTypeError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const toggleModal = () => {
    setOpenModal((prevState) => !prevState);
  };

  const [openSnackbar] = useSnackbar();

  function getAdminNotification() {
    const params = {
      type: type,
      title: title,
      description: description,
    };

    Core.getAdminNotification(
      getAdminNotificationSuccess,
      getAdminNotificationFailed,
      (status) => setEffectiveLoad(status),
      params
    );
  }
  const getAdminNotificationSuccess = (response) => {
    // console.log("response", response);
    setTitle("");
    setDescription("");
    // console.log("Setting type to empty");
    setType("");
    toggleModal();
  };

  const getAdminNotificationFailed = (errorList) => {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  };

  const handleTypeChange = (value) => {
    setType(value[0].value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmit = (e) => {
    let valid = true;
    setTypeError("");
    setTitleError("");
    setDescriptionError("");

    if (!type) {
      setTypeError("Type is required.");
      valid = false;
    }
    if (!title) {
      setTitleError("Title is required.");
      valid = false;
    }
    if (!description) {
      setDescriptionError("Description is required.");
      valid = false;
    }

    if (!valid) {
      return;
    }
    toggleModal();
  };

  function typeSelection() {
    return (
      <div className="flex-column " style={{ gap: "10px" }}>
        <label>Select Notification Type</label>
        <div className="m-l-5">
          <Select
            multi={false}
            options={[
              {
                value: "Organization",
                label: "Organization",
              },
              { value: "Influencer", label: "Influencer" },
            ]}
            value={type ? { value: type, label: type } : null}
            onChange={(selectedOption) => handleTypeChange(selectedOption)}
            placeholder="Select Type of Notification"
            autoFocus={false}
            color="#c8c2c2"
            style={{
              border: "#fff",
              backgroundColor: "white",
              paddingBottom: "10px",
              paddingTop: "10px",
              option: {
                backgroundColor: "red",
              },
            }}
          />
        </div>
      </div>
    );
  }

  function InputFeildText() {
    return (
      <div
        className="m-l-12"
        style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
        <label> Title of Notification </label>
        <div className="m-l-5">
          <CustomTextInput
            newWidth="365px"
            className=""
            name="title"
            value={title}
            type="text"
            onChange={handleTitleChange}
            placeholder={"Title"}
          />
        </div>
      </div>
    );
  }

  function descriptionTextArea() {
    return (
      <div className="m-l-12">
        <label> Description </label>
        <div className="m-t-20 brand-text-area " style={{ width: "410px" }}>
          <CustomTextArea
            // invert
            className="custom-text-offer"
            rows={5}
            value={description}
            onChange={handleDescriptionChange}
            placeholder={"Write a Description"}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="dashboard-container">
      <SideMenu />
      <div className="main-screen">
        <Header />
        <div className="page-container-campaignList">
          {isLoading ? (
            <div className="loader-mid">
              <Loader />
            </div>
          ) : (
            <div>
              <div className="m-b-16 m-l-20">
                <CustomText
                  h3
                  text={"Notification"}
                  textColor={"text_color_primary"}
                  fontSize={"font_size_4xl"}
                  height={"line_height_24"}
                />
              </div>

              {/* --------- Notification Div --------- */}
              <div
                className="engagement-view-container-kyc"
                style={{
                  minHeight: "420px",
                  marginLeft: "20px",
                  alignItems: "flex-start",
                }}>
                <div className="engagement-view-inner">
                  <div className="instagram-data-view">
                    {/* ----- Input Feilds ----- */}
                    <div className="m-b-15">
                      {InputFeildText()}
                      {titleError && (
                        <p className="m-l-15 p-t-10" style={{ color: "red" }}>
                          {titleError}
                        </p>
                      )}

                      {/* ------ Description area */}
                    </div>
                    <div className="">
                      {descriptionTextArea()}
                      {descriptionError && (
                        <p className="m-l-15 p-t-10" style={{ color: "red" }}>
                          {descriptionError}
                        </p>
                      )}
                    </div>

                    <div className="m-b-19">
                      <PrimaryCta
                        third
                        fontSize={"font_size_regular"}
                        className="header-credit-right"
                        text={"Send Notifications"}
                        isLoading={effectiveLoad}
                        onClick={() => handleSubmit()}
                      />
                    </div>

                    {/* ------------ Modal ---------- */}

                    <ModalComponent
                      isOpen={openModal}
                      onRequestClose={() => toggleModal()}
                      customStyle={{
                        overlay: {
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          zIndex: 999999,
                        },
                        content: {
                          top: "50%",
                          left: "50%",
                          right: "auto",
                          bottom: "auto",
                          // borderColor: "#f55879",
                          backgroundColor: "#F0F3F9",

                          marginRight: "-50%",
                          transform: "translate(-50%, -50%)",
                          padding: "20px",
                          borderRadius: "8px",
                          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
                        },
                      }}>
                      <div className="">
                        <CustomText
                          h5
                          text={"Are you sure for Sending Notification?"}
                          textColor={"text_grey_400_color"}
                          className="m-b-8 typographyBoldH5"
                        />
                      </div>
                      <div className="modal-btn-center">
                        <PrimaryCta
                          invert
                          className="back-btn-campaign"
                          onClick={() => toggleModal()}
                          fontSize={"font_size_large"}
                          text={"No"}
                        />
                        <PrimaryCta
                          third
                          fontSize={"font_size_regular"}
                          className="header-credit-right"
                          text={"Yes"}
                          isLoading={effectiveLoad}
                          onClick={() => getAdminNotification()}
                        />
                      </div>
                    </ModalComponent>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NotificationPage;
