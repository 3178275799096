import React, { useState, useEffect } from "react";

import Modal from "react-modal";
import {
  CustomText,
  PrimaryCta,
  TeamMembers,
  CustomTextInput,
  CustomTextArea,
  Video,
  UnderLinedLinkCta,
} from "../../components";
import Core from "../../common/clientSdk";
import { Loader } from "../loader";
import { useNavigate } from "react-router-dom";
import Icon from "../../components/core_component/icon";
import { useSnackbar } from "react-simple-snackbar";
import { Image } from "../../components";
import Select from "react-dropdown-select";
import Multiselect from "multiselect-react-dropdown";
import * as CampaignData from "../../common/data/campaign";
import Style from "../../resources/style";
import { autocompleteClasses } from "@mui/material";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import RichTextEditor from "react-rte";
import ReactHtmlParser from "react-html-parser";
import { WithContext as ReactTags } from "react-tag-input";
import { useDropzone } from "react-dropzone";
import UploadImageS3 from "../../common/s3";
import utils from "../../common/utils";

const strictList = [
  { value: false, label: "No" },
  { value: true, label: "Yes" },
];

const toolbarConfig = {
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],

  LINK_BUTTONS: [{ style: { display: "" } }],
};
function EditCampaignDetailsScreen({
  closeModal,
  campaignId,
  successCampaignDetail,
}) {
  const [selectValue, setSelectValue] = useState();
  const [campaignDetail, setCampaignDetail] = useState({});
  const [campaignName, setCampaignName] = useState();
  const [budget, setBudget] = useState();
  const [descriptionOld, setDescriptionOld] = useState();
  const [openSnackbar] = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [ctaUrl, setCtaUrl] = useState("");
  const [ctaText, setCtaText] = useState("");
  const [tags, setTags] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedLocalImage, setSelectedLocalImage] = useState([]);
  const [isUploading, setUploading] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [imageId, setImageId] = useState([]);
  const [productUrl, setProductUrl] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [checked, setChecked] = useState(false);
  const [strict, setStrict] = useState(selectValue);
  const [description, setDescription] = useState(
    RichTextEditor.createEmptyValue()
  );

  const instructionNoteText = description.toString("html");

  useEffect(() => {
    if (campaignDetail?.campaign_data?.is_strict == 1) {
      setStrict([{ value: true, label: "Yes" }]);
    } else {
      setStrict([{ value: false, label: "No" }]);
    }
  }, [campaignDetail?.campaign_data?.is_strict]);

  useEffect(() => {
    getCampaignUserDetail();
  }, []);

  useEffect(() => {
    setCampaignName(campaignDetail?.campaign_data?.name);
    setBudget(campaignDetail?.campaign_data?.max_budget);
    setCtaUrl(campaignDetail?.campaign_data?.url);
    setProductUrl(campaignDetail?.campaign_data?.product_url);
    setProductPrice(campaignDetail?.campaign_data?.product_price);
    setImageId(
      campaignDetail?.sample_content_data?.map((item) => item.sample_content_id)
    );
    setDescription(
      RichTextEditor.createValueFromString(
        campaignDetail?.instruction_data?.instruction_note,
        "html"
      )
    );
  }, [campaignDetail]);

  function updateProgress(status) {
    setLoading(status);
  }

  const redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { BrandUser = {} } = redux_selector;
  const { brand_deep_data = {} } = BrandUser;
  const { brand_data = {} } = brand_deep_data;

  function getCampaignUserDetail() {
    const params = {
      brand_id: brand_data.brand_id,
      campaign_id: campaignId,
    };
    Core.getCampaignUserDetail(
      getCampaignUserDetailSuccess,
      getCampaignUserDetailFailed,
      updateProgress,
      params
    );
  }

  function getCampaignUserDetailSuccess(response_data) {
    setCampaignDetail(response_data.campaign_data);
    setLoading(false);
  }

  function getCampaignUserDetailFailed(errorList) {
    toast.info(errorList.map((item) => item.m).join(", "));
  }

  function updateCampaignDetails() {
    if (!formValidate()) {
      return false;
    }
    var params = {
      campaign_deep_data: JSON.stringify({
        campaign_data: {
          name: campaignName,
          max_budget: budget,
          url: ctaUrl,

          product_price: productPrice,
          product_url: productUrl,
          is_strict: strict[0].value,
        },
        instruction_data: {
          instruction_note: instructionNoteText,
        },
        sample_content_data: imageId,
      }),
      campaign_id: campaignId,
    };
    Core.postUpdateCampaign(
      updateCampaignDetailsSuccess,
      updateCampaignDetailsFail,
      updateProgress,
      params
    );
  }

  function updateCampaignDetailsSuccess(response) {
    toast.info("Campaign details updated Successfully");
    closeModal();
    successCampaignDetail();
  }

  function updateCampaignDetailsFail(errorList) {
    toast.info(errorList.map((item) => item.m).join(", "));
  }

  function generateUploadUrl(uploadedFileObject) {
    var params = {
      action: "sample_content",
      id: brand_data.brand_id,
      file_type: uploadedFileObject.type,
    };
    setUploading(true);
    Core.getGenerateUploadUrl(
      generateUrlSuccess,
      generateUrlFailed,
      () => {},
      params,
      () => {},
      uploadedFileObject
    );
  }

  function generateUrlSuccess(response, uploadedFileObject) {
    if (response) {
      // upload image file on server call uploadImageS3
      UploadImageS3(uploadedFileObject, response.upload_data, (error) => {
        setUploading(false);
        if (error) {
          return toast.info("Upload failed");
        }
        let newImageId = imageId;
        newImageId.push(response.upload_data.media_id);
        setImageId(newImageId);
      });
    } else {
      setUploading(false);
      toast.info("Something went wrong");
    }
  }

  function generateUrlFailed(error) {
    toast.info("Something went wrong");
    setUploading(false);
  }

  const removeHtmlTags = () => {
    const doc = new DOMParser().parseFromString(
      instructionNoteText,
      "text/html"
    );
    return doc.body.textContent || "";
  };

  let descriptionLength = removeHtmlTags();

  function formValidate() {
    if (campaignName.length < 6) {
      toast("Please enter minimum 6 character for campaign title");
      return false;
    }
    const urlRegEx = /^(http|https):\/\/[^ "]+$/;
    if (checked && campaignDetail?.campaign_data?.collab_type !== "BARTER") {
      if (!productPrice) {
        toast.info("Please Enter Product Price");
        return false;
      }
      if (Number(productPrice) < 100) {
        toast.info("Product Price should be atleast 100");
        return false;
      }
      if (!productUrl) {
        toast.info("Please enter product url");
        return false;
      }

      if (productUrl.length > 0 && !urlRegEx.test(productUrl)) {
        toast.info("Please enter at correct product url");
        return false;
      }
    }

    if (campaignDetail?.campaign_data?.collab_type !== "BARTER") {
      if (!budget) {
        toast("Please enter maximum proposal amount");
        return false;
      }

      if (Number(budget) <= 99) {
        toast("Please enter more than 100 proposal amount");
        return false;
      }

      // if (Number(budget) >= 50000) {
      //   toast("Please enter less than 50000 proposal amount");
      //   return false;
      // }
    }

    if (descriptionLength.length < 20) {
      toast("Please enter at least 20 character in creator's instruction");
      return false;
    }

    if (ctaUrl && ctaUrl.length > 0 && !urlRegEx.test(ctaUrl)) {
      toast("Please enter at correct url");
      return false;
    }

    return true;
  }
  const handleCheckboxChange = () => {
    setChecked(!checked);
  };
  const handleDelete = (i) => {
    const newTags = [...tags];
    newTags.splice(i, 1);
    setTags(newTags);
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleClick = (index) => {
    setShowImage(!showImage);
    setSelectedIndex(index);
  };
  const handleCropImage = (acceptedFiles) => {
    if (acceptedFiles.length + selectedLocalImage.length <= 3) {
      const updatedImages = [...selectedLocalImage];
      for (let i = 0; i < acceptedFiles.length; i++) {
        updatedImages.push(acceptedFiles[i]);
        generateUploadUrl(acceptedFiles[i]);
      }
      setSelectedLocalImage(updatedImages);
    } else {
      toast.info("Maximum 3 files allowed");
    }
  };

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    onDrop: handleCropImage,
    accept: "image/*, video/mp4", // Limit to image files
    // maxSize: 5000000, // Limit to 5MB per file
    multiple: true, // Allow multiple file uploads
  });

  const handleSampleDelete = (index) => {
    // const updatedSampleContent = [...campaignDetail.sample_content_data];
    // updatedSampleContent.splice(index, 1);
    // setCampaignDetail({
    //   ...campaignDetail,
    //   sample_content_data: updatedSampleContent,
    // });
  };

  const handleRemoveItem = (indexToRemove) => {
    // Create a copy of the array without the item at the specified index
    const updatedImageId = [...imageId];
    updatedImageId.splice(indexToRemove, 1);

    // Update the state or perform any necessary actions
    // For example, if imageId is a state variable, you might use a state update function
    setImageId(updatedImageId);
  };
  function updateStrict(selectedItem) {
    setStrict(selectedItem);
  }
  function SampleContent() {
    return (
      imageId &&
      imageId?.map((item, index) => {
        if (item?.split(".").pop() == "mp4") {
          return (
            <div key={index} className="product-sample-main">
              <div id={index} className="m-r-6">
                <div
                  onClick={() => handleRemoveItem(index)}
                  className="sample-content-remove-btn cursor-pointer"
                >
                  <Image
                    source={require("../../resources/images/bid-search-cross.png")}
                  />
                </div>
                <video
                  preload="metadata"
                  style={{ height: 106, width: 106 }}
                  src={utils.generateCampaignSampleUrl(
                    campaignDetail.campaign_data.brand_id,
                    item
                  )}
                />
              </div>
              {/* {showVideo && (
                <div className="fullscreen-image">
                  <div style={{ position: "relative" }}>
                    <div
                      onClick={() => handleVideoClick()}
                      className="fullscreen-image-close-btn"
                    >
                      <Icon iconName="CloseRoundButtonIcon" />
                    </div>
                    <Video
                      source={utils.generateCampaignSampleUrl(
                        campaignDetail.campaign_data.brand_id,
                        item.sample_content_id
                      )}
                      width={600}
                      height={337}
                      type={"video/mp4"}
                    />
                  </div>
                </div>
              )} */}
            </div>
          );
        } else {
          return (
            <>
              <div className="product-sample-main">
                <div onClick={() => handleClick()}>
                  <div
                    onClick={() => handleRemoveItem(index)}
                    className=" sample-content-remove-btn cursor-pointer"
                  >
                    <Image
                      source={require("../../resources/images/bid-search-cross.png")}
                    />
                  </div>
                  <Image
                    source={utils.generateCampaignSampleUrl(
                      campaignDetail.campaign_data.brand_id,
                      item
                    )}
                    width={106}
                    height={106}
                  />
                </div>
                {/* {showImage && (
                  <div className="fullscreen-image">
                    <div style={{ position: "relative" }}>
                      <div
                        onClick={() => handleClick()}
                        className="fullscreen-image-close-btn"
                      >
                        <Icon iconName="CloseRoundButtonIcon" />
                      </div>
                      <Image
                        source={utils.generateCampaignSampleUrl(
                          campaignDetail.campaign_data.brand_id,
                          item.sample_content_id
                        )}
                        width={600}
                        height={337}
                      />
                    </div>
                  </div>
                )} */}
              </div>
              ;
            </>
          );
        }
      })
    );
  }

  return (
    <>
      <Modal isOpen={true} onRequestClose={closeModal} style={customStyles}>
        <div className="campaign-edit-page" 
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          background: "#ffffff",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 40,
          paddingTop:15
        }}>
          <div className="">
        <CustomText
          h5
          text={"Edit Campaign"}
          textColor={"text_grey_400_color"}
          className="m-b-20 typography-centerH7"
        />
        <div onClick={closeModal} className="close-btn-icon">
          <Icon iconName="CloseRoundButtonIcon" />
        </div>
        </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="edit-campaign-main-container" style={{ paddingTop: 30 }}>
            <div className="edit-campaign-input-field">
              <div>
                <div className="m-l-12 m-b-10" >
                  <CustomText
                    h5
                    text={"Title"}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_grey_400_color"}
                    height={"line_height_19_2"}
                  />
                </div>
                <div className="m-b-10">
                <CustomTextInput
                  invert
                  value={campaignName}
                  placeholder={"New year sale"}
                  onChange={(e) => setCampaignName(e.target.value)}
                />
                </div>
              </div>
              {campaignDetail?.campaign_data?.collab_type !== "BARTER" && (
                <div>
                  <div className="m-l-12 m-b-10">
                    <CustomText
                      h5
                      text={"Maximum Proposal Amount"}
                      fontSize={"font_size_large"}
                      textAlign={"text_align_left"}
                      textColor={"text_grey_400_color"}
                      height={"line_height_19_2"}
                    />
                  </div>

                  <CustomTextInput
                    invert
                    placeholder={"Maximum Proposal Amount"}
                    value={budget}
                    onChange={(e) =>
                      setBudget(e.target.value.replace(/\D/g, ""))
                    }
                  />
                </div>
              )}

              <div className="">
                <div className="m-l-12 m-b-10">
                  <CustomText
                    h5
                    text={"URL"}
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_grey_400_color"}
                    height={"line_height_19_2"}
                  />
                </div>

                <CustomTextInput
                  invert
                  value={ctaUrl}
                  placeholder={"e.g. https://mystore.com/collection"}
                  onChange={(e) => setCtaUrl(e.target.value)}
                />
              </div>

              {/* <div style={{ width: "340px" }} className="m-l-12 ">
                <div className="m-b-8">
                  <CustomText
                    h5
                    fontSize={"font_size_large"}
                    textAlign={"text_align_left"}
                    textColor={"text_grey_400_color"}
                    height={"line_height_19_2"}
                    text={"Strict campaign"}
                  />
                </div>

                <Select
                  multi={false}
                  options={strictList}
                  values={strict}
                  onChange={(value) => updateStrict(value)}
                  autoFocus={false}
                  singleSelect
                  color="#c8c2c2"
                  // Customcolor
                  style={{
                    border: "#fff",
                    backgroundColor: "#fff",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    option: {
                      backgroundColor: "red",
                    },
                  }}
                />

                <BorderedDropdown invert placeholder={"Choose gender"} />
              </div> */}

              {/* {campaignDetail?.campaign_data?.type == "social_media" && (
                <div className="">
                  <div className="m-l-12">
                    <CustomText
                      p
                      fontSize={"font_size_medium"}
                      textColor={"text_color_light"}
                      text={"Link Text"}
                      textAlign={"text_align_left"}
                    />
                  </div>

                  <CustomTextInput
                    invert
                    value={ctaText}
                    placeholder={"Link Text"}
                    onChange={(e) => setCtaText(e.target.value)}
                  />
                </div>
              )} */}
              {/* {campaignDetail?.campaign_data?.type == "social_media" && (
                <div>
                  <div className="m-l-12">
                    <CustomText
                      p
                      fontSize={"font_size_medium"}
                      textColor={"text_color_light"}
                      text={"Hashtag"}
                      textAlign={"text_align_left"}
                    />
                  </div>

                  <ReactTags
                    tags={tags}
                    placeholder="Add Hashtag"
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    autofocus={false}
                    inputFieldPosition="top"
                    classNames="react-tag-create-campaign"
                  />
                </div>
              )} */}
            </div>
            <div>
              <div>
                <div>
                  {campaignDetail?.campaign_data?.collab_type !== "BARTER" && (
                    <div className="m-t-12 m-l-10 flex-row ">
                      <input
                        type="checkbox"
                        checked={checked}
                        onChange={handleCheckboxChange}
                        style={{
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                        }}
                      />
                      <CustomText
                        p
                        fontSize={"font_size_medium"}
                        textColor={"text_color_primary"}
                        text={
                          "Does this campaign requires a product to be purchased?"
                        }
                        className="m-l-5"
                      />
                    </div>
                  )}
                  {(checked ||
                    campaignDetail?.campaign_data?.collab_type == "BARTER") && (
                    <div className="flex-row m-t-12">
                      {" "}
                      <div>
                        <div className="m-l-12 m-b-8">
                          <CustomText
                            h5
                            text={"Product Price"}
                            fontSize={"font_size_large"}
                            textAlign={"text_align_left"}
                            textColor={"text_grey_400_color"}
                            height={"line_height_19_2"}
                          />
                        </div>

                        <CustomTextInput
                          invert
                          placeholder={"Product Price"}
                          value={productPrice}
                          onChange={(e) =>
                            setProductPrice(e.target.value.replace(/\D/g, ""))
                          }
                        />
                      </div>
                      <div className="">
                        <div className="m-l-12 m-b-8">
                          <CustomText
                            h5
                            text={"Product url"}
                            fontSize={"font_size_large"}
                            textAlign={"text_align_left"}
                            textColor={"text_grey_400_color"}
                            height={"line_height_19_2"}
                          />
                        </div>

                        <CustomTextInput
                          invert
                          value={productUrl}
                          placeholder={"e.g. https://mystore.com/collection"}
                          onChange={(e) => setProductUrl(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <>
              <div className="m-l-12 m-t-16">
                <CustomText
                  h5
                  text={"Instruction for Creator"}
                  fontSize={"font_size_large"}
                  textAlign={"text_align_left"}
                  textColor={"text_grey_400_color"}
                  height={"line_height_19_2"}
                />
              </div>

              <div className="edit-details-text-area m-t-4">
                <RichTextEditor
                  toolbarConfig={toolbarConfig}
                  value={description}
                  onChange={(newValue) => setDescription(newValue)}
                />
                {/* <CustomTextArea
                  invert
                  placeholder={"Provide clear product image"}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                /> */}
              </div>
              <div className="m-t-16">
                <CustomText
                  h5
                  text={"Sample Content (Max 3)"}
                  textAlign={"text_align_left"}
                  fontSize={"font_size_large"}
                  textColor={"text_grey_400_color"}
                  height={"line_height_19_2"}
                />
                <div style={{ position: "relative" }}>
                  <div {...getRootProps({ className: "dropzone" })}>
                    <div className="upload-content m-t-12">
                      <div className="upload-content-inner">
                        <input {...getInputProps()} />

                        <Image
                          source={"../assets/images/Uploadicon.svg"}
                          height={72}
                          width={82}
                        />
                        <div className="flex-row">
                          <CustomText
                            p
                            fontSize={"font_size_large"}
                            text={"Drag & drop files or "}
                            textColor={"text_color_black"}
                          />
                          <UnderLinedLinkCta
                            fontSize={"font_size_large"}
                            text={`\u00A0Browse`}
                            textColor={"text_Gradient_Color"}
                            // onClick={open}
                          />
                        </div>

                        <div>
                          <CustomText
                            p
                            fontSize={"font_size_label"}
                            textColor={"text_light_weight_color"}
                            text={"Supported formates: JPEG, PNG, MP4 "}
                          />
                        </div>
                      </div>

                      <div style={{ position: "absolute" }}>
                        {isUploading && <Loader />}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="m-t-12 flex-row">
                  <div className="product-sample-main">
                    {selectedLocalImage &&
                      selectedLocalImage.map((image, index) => {
                        return (
                          <>
                            {image.type == "video/mp4" ? (
                              <video
                                preload="metadata"
                                style={{ height: 106, width: 106 }}
                                src={URL.createObjectURL(image)}
                                onClick={() => handleClick(index)}
                              />
                            ) : (
                              <img
                                alt="not fount"
                                width={"106px"}
                                height={"106px"}
                                src={URL.createObjectURL(image)}
                                onClick={() => handleClick(index)}
                              />
                            )}

                            {showImage &&
                              selectedIndex === index && ( // Check if selectedIndex matches current index
                                <div className="fullscreen-image-sample-content">
                                  <div style={{ position: "relative" }}>
                                    <div
                                      className="fullscreen-image-close-btn"
                                      onClick={() => handleClick(index)}
                                    >
                                      <Icon iconName="CloseRoundButtonIcon" />
                                    </div>
                                    {image.type == "video/mp4" ? (
                                      <Video
                                        source={URL.createObjectURL(image)}
                                        width={600}
                                        height={337}
                                        type={"video/mp4"}
                                      />
                                    ) : (
                                      <Image
                                        source={URL.createObjectURL(image)}
                                        width={600}
                                        height={337}
                                      />
                                    )}
                                  </div>
                                </div>
                              )}
                          </>
                        );
                      })}
                  </div>
                </div> */}
              </div>
              <div className="flex-row m-t-8">{SampleContent()}</div>
            </>

            <div className="m-t-20 text-align-end">
              <PrimaryCta
                fifth
                fontSize={"font_size_large"}
                onClick={() => updateCampaignDetails()}
                text={"Update"}
              />
            </div>
          </div>
        )}
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </Modal>
    </>
  );
}

export default EditCampaignDetailsScreen;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    borderColor: "var(--gray-gray-50)",
    textAlign: "center",
    zIndex: "5",
    minHeight: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    paddingLeft: 30,
    width: "768px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.86)",
    zIndex: 10,
  },
};
