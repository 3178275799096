// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// import { getToken } from "firebase/app-check";
import { getToken, getMessaging, onMessage } from "firebase/messaging";
import WebStorage from "./web_storage";
import { DEVICE_TOKEN } from "./web_storage_keys";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDT7_PiF41y3s0UXwz1tkRtNFMF5irBfcI",
  authDomain: "reelax-2807d.firebaseapp.com",
  projectId: "reelax-2807d",
  storageBucket: "reelax-2807d.appspot.com",
  messagingSenderId: "723018555590",
  appId: "1:723018555590:web:945b4534a3ab339dce77c7",
  measurementId: "G-W7LW5KMEWG",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

// export const messaging = getMessaging(app);

export var messaging = null;

if (window.Notification && window.Notification.permission == "granted") {
  messaging = getMessaging(app);
}

export const getNotificationToken = getToken;

export const requestPermission = () => {
  if (window.PushManager) {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted");
      } else {
        console.log("User permission denied");
      }
    });
  }
};
//requestPermission();
export const onMessageListener = () => {
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
};
