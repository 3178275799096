// Dependencies
import React, { useState, useEffect, Suspense } from "react";

// Import Redux State (Private scope)
import { useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";

// Navigation
import RootNavigator from "./navigation/root_navigation";

import * as Actions from "./redux/actions";

// Core Sdk (Private Scope)
import Core from "./common/clientSdk.js";

import Utils from "./common/utils.js";
import WebStorage from "./common/web_storage";
import {
  ORG_ID_KEY,
  BRAND_ID_KEY,
  DEVICE_TOKEN,
} from "./common/web_storage_keys";
import { Loader } from "./screens/loader";
import ReactGA from "react-ga";
import { Crisp } from "crisp-sdk-web";
import {
  requestPermission,
  onMessageListener,
  messaging,
  getNotificationToken,
} from "./common/firebase.js";
import addNotification, { Notifications } from "react-push-notification";
import NotificationPrompt from "./screens/common/notification.js";

// import "primereact/resources/themes/saga-blue/theme.css"; // theme can be changed
// import "primereact/resources/primereact.min.css";
// import "primeicons/primeicons.css";
const TRACKING_ID = "G-PTRN15HW4P";

ReactGA.initialize(TRACKING_ID);

const App = function (props) {
  var navigate = useNavigate();

  const [appLoaded, setAppLoaded] = useState(true);
  const [loading, set_loading] = useState(false);
  const [error, set_error] = useState(null);
  const [notification, setNotification] = useState({});
  const orgId = WebStorage.getDataFromWebStorage(ORG_ID_KEY);
  let deviceToken = WebStorage.getDataFromWebStorage(DEVICE_TOKEN);

  useEffect(() => {
    // requestPermission();
    // onMessageListener().then((payload) => {
    //   console.log("payload", payload);
    //   addNotification({
    //     title: payload.notification.title,
    //     message: payload.notification.body,
    //     // message: "This is a very long message",
    //     theme: "darkblue",
    //     duration: 5000,
    //     // native: true, // when using native, your OS will handle theming.
    //   });
    //   setNotification({
    //     title: payload.notification.title,
    //     body: payload.notification.body,
    //   });
    // });
  }, []);

  useEffect(() => {
    // if (data) {
    //   console.log("kkk", JSON.parse(data.data).notification_data);
    //   notificationType = JSON.parse(data.data).notification_type;
    //   console.log("notificationType", notificationType);
    //   campaignId = JSON.parse(data.data).notification_data.bid_data.campaign_id;
    //   console.log("campaignId", campaignId);
    // }
  }, []);

  if (process.env.NODE_ENV == "production") {
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
  }

  useEffect(() => {
    adminSelfData(updateProgress);
    // Crisp.configure("51773738-d935-4511-ae8c-4b55d5e0ab46");
  }, []);

  // Initialize Private Functions
  var redux_dispatch = useDispatch();

  /********************************************************************
  Get User Data Self

  @param {Function} progress_cb - manage status of screen

  No return
  *********************************************************************/
  function adminSelfData(progress_cb) {
    var params = {};

    Core.adminSelfData(
      adminSelfDataSuccess,
      adminSelfDataFailed,
      progress_cb,
      params
    );
  }

  /********************************************************************
  Brand Login success

  @param {Set} response_data - consisting user_data and brand_data

  No return
  *********************************************************************/

  // Function to find the brand with a specific brandId
  function findBrandById(brandId, brandList) {
    for (const brandData of brandList) {
      if (brandData.brand_data.brand_id === brandId) {
        return brandData;
      }
    }
    return null; // Return null if the brandId is not found
  }

  function adminSelfDataSuccess(response_data) {
    console.log("responseData",response_data)
    redux_dispatch({
      type: Actions.UPDATE_ADMIN_DATA,
      payload:response_data.admin_data ,
    });
  }

  /********************************************************************
  Brand Login Failed

  @param {Array} error_list - consisting Error List

  No return
  *********************************************************************/
  function adminSelfDataFailed(error) {
    set_error(error);

    setAppLoaded(true);

    const nonLoginRoute = ["/login"];

    if (!nonLoginRoute.includes(window.location.pathname)) {
      navigate("/login");
    }
  }

  /********************************************************************
  Screen Progress

  @param {Boolean} status - true/false

  No return
  *********************************************************************/
  function updateProgress(status) {
    set_loading(status);
  }

  // Return App
  return (
    <>
      {/* <NotificationPrompt />
      <Notifications position="top-right" /> */}

      {loading ? (
        <div className="loader-mid">
          <Loader />
        </div>
      ) : (
        appLoaded && (
          <>
            <RootNavigator />
          </>
        )
      )}
    </>
  );
};

export default App;
