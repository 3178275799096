import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import Core from "../../common/clientSdk";
import cross from "../../resources/images/bid-search-cross.png";

import SideMenu from "../sideMenu";
import { Loader } from "../loader";
import {
  CustomText,
  Image,
  CustomButton,
  PrimaryCta,
  FallBack,
  CampaignListDropdown,
  PayoutListDropdown,
} from "../../components";
import CustomTable from "../../components/customTable";
import { useSnackbar } from "react-simple-snackbar";
import Utils from "../../common/utils";
import { useNavigate } from "react-router-dom";
import { Icon } from "@mui/material";
import Header from "../header";

function PayoutAdminList() {
  const [payoutListData, setPayOutListData] = useState([]);
  const [filterStatus, setFilterStatus] = useState("ALL");
  const [next, setNext] = useState(10);
  const [isLoading, setLoading] = useState(false);
  const [openSnackbar] = useSnackbar();
  const [searchName, setSearchName] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState(searchName);

  const PayoutStatusList = ["ALL", "PENDING", "COMPLETED"];

  const navigate = useNavigate();

  useEffect(() => {
    if (searchName != null) {
      payoutListAdmin();
      setLoading(false);
    }
  }, [searchName]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchName);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [searchName]);

  console.log("search", searchName);

  useEffect(() => {
    payoutListAdmin(updateProgress);
  }, [filterStatus]);

  function updateProgress(status) {
    setLoading(status);
  }

  function clearSearchText() {
    setSearchName("");
    setDebouncedSearch("");
    console.log("clicked");
  }

  const payoutListAdmin = () => {
    const params = {
      // start : 0,
      // limit : 10,
    };
    if (filterStatus !== "ALL") {
      params.status = filterStatus;
    }
    if (searchName != null) {
      params.search_name = searchName;
    }

    Core.payoutListAdmin(
      payoutListAdminSuccess,
      payoutListAdminFailed,
      updateProgress,
      params
    );
  };

  const payoutListAdminSuccess = (response) => {
    console.log("response Checking", response);

    setPayOutListData(response.payout_data || []);
  };
  // console.log("data of list", payoutListData);

  const payoutListAdminFailed = (errorList) => {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  };

  const payoutClickWithID = (payout_id) => {
    navigate(`/payout/${payout_id}`);
  };

  const handleSearchChange = (e) => {
    setSearchName(e.target.value);
    setIsSearching(true);

    setTimeout(() => {
      setIsSearching(false);
    }, 2000);
  };

  const PayoutTableSchema = [
    { name: "Influencer Name", selector: (row) => row["influencer_name"] },
    { name: "Payout ID", selector: (row) => row["payout_id"] },
    {
      name: "Brand Name",
      selector: (row) => row["brand_name"],
      textAlign: "center",
    },
    {
      name: "Schedule Date",
      selector: (row) => row["date_scheduled"],
      textAlign: "center",
    },
    { name: "Status", selector: (row) => row["status"] },
    {
      name: "Order Amount",
      selector: (row) => row["order_amount"],
      textAlign: "center",
    },
    { name: "Platform Charges", selector: (row) => row["platform_charges"] },
    { name: "Tax Amount", selector: (row) => row["tax_amount"] },
    { name: "Payout Amount", selector: (row) => row["payout_amount"] },
  ];

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "var(--general-secondary-color)",
        color: "var(--gray-gray-300)",
        fontSize: "13px",
        fontWeight: 600,
        textAlign: "center",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        fontSize: "15px",
        fontWeight: 400,
        "&:hover": {
          backgroundColor: "var(--general-secondary-color)",
          fontWeight: 600,
        },
      },
    },
  };

  const tableData = payoutListData.map((payout) => ({
    influencer_name: payout.influencer_name || "N/A",
    payout_id: payout.payout_id,
    brand_name: payout.brand_name,
    date_scheduled: Utils.formatDate(payout.date_scheduled),
    status: payout.status,
    order_amount: payout.order_amount,
    platform_charges: payout.platform_charges,
    tax_amount: payout.tax_amount,
    payout_amount: payout.payout_amount,
  }));

  return (
    <div className="dashboard-container">
      <SideMenu />
      <div className="main-screen">
        <Header />
        <div className="page-container-campaignList">
          {isLoading ? (
            <div className="loader-mid">
              <Loader />
            </div>
          ) : (
            <>
              <div className="campaignlist-header">
                <PayoutListDropdown
                  dropdownItems={PayoutStatusList}
                  onItemClick={(item) => {
                    setFilterStatus(item);
                  }}
                />
              </div>

              {/* ----------- Search Bar --------- */}
              <div className="payoutSearchTab">
                {/* <div>
                <input
                  className="bid-search-payout"
                  type="text"
                  style={{ border: "2px" }}
                  value={searchName}
                  placeholder="Search for Payout List "
                  onChange={handleSearchChange}
                />
              </div> */}
                {/* <div className="search-filter-icon-payout"
              onClick={clearSearchText}>
                <Image
                  source={require("../../resources/images/bid-search-cross.png")}
                  // onClick={()=> clearSearchText}
                />
              </div> */}
              </div>

              {isSearching ? (
                <div className="loader-mid">
                  <Loader />
                </div>
              ) : payoutListData.length !== 0 ? (
                <div className="custom-table">
                  <CustomTable
                    className="CustomTable"
                    tableSchema={PayoutTableSchema}
                    paginationPerPage={10}
                    tableData={tableData}
                    customStyles={customStyles}
                    onRowClicked={(item) => payoutClickWithID(item.payout_id)}
                    paginationTotalRows={payoutListData.length}
                    pagination
                  />
                </div>
              ) : (
                <div className="fallback">
                  <FallBack heading={"No Payout List Available"} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PayoutAdminList;
