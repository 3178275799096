import React, { useEffect, useState } from "react";
import Core from "../../common/clientSdk";
import { useSnackbar } from "react-simple-snackbar";
import SideMenu from "../sideMenu";
import { Loader } from "../loader";
import { useNavigate } from "react-router-dom";
import { CustomText, FallBack } from "../../components";
import Header from "../header";
import CustomTable from "../../components/customTable";
import Utils from "../../common/utils";

function KycList() {
  const [kycListData, setKycListData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [openSnackbar] = useSnackbar();

  const navigate = useNavigate();

  function updateProgress(status) {
    setLoading(status);
  }

  useEffect(() => {
    getAdminKycList(updateProgress);
  }, []);

  const getAdminKycList = () => {
    const params = {};
    // if (filterStatus !== "ALL") {
    //   params.status = filterStatus;
    // }

    Core.getAdminKycList(
      getAdminKycListSuccess,
      getAdminKycListFailed,
      updateProgress,
      params
    );
  };

  const getAdminKycListSuccess = (response) => {
    // console.log("response Checking",response.kyc_list);
    setKycListData(response.kyc_list);
  };

  console.log("list", kycListData);

  const getAdminKycListFailed = (errorList) => {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  };

  // const kyc_id = kycListData.kyc_id;
  // console.log("id", kyc_id);

  const kycDetailPageNavigate = (kyc_id) => {
    navigate(`/kyc-detail/${kyc_id}`);
  };

  const kycTableSchema = [
    { name: "Actor Type", selector: (row) => row["actor_type"] },
    { name: "Influencer ID", selector: (row) => row["influencer_id"] },
    { name: "Kyc ID", selector: (row) => row["kyc_id"] },
    {
      name: "Kyc Type",
      selector: (row) => row["kyc_type"],
      textAlign: "center",
    },
    {
      name: "Status",
      selector: (row) => row["status"],
      textAlign: "center",
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "var(--general-secondary-color)",
        color: "var(--gray-gray-300)",
        fontSize: "13px",
        fontWeight: 600,
        textAlign: "center",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        fontSize: "15px",
        fontWeight: 400,
        "&:hover": {
          backgroundColor: "var(--general-secondary-color)",
          fontWeight: 600,
        },
      },
    },
  };

  const tableData = kycListData.map((kyc) => ({
    actor_type: kyc.actor_type || "N/A",
    influencer_id: kyc.influencer_id || "N/A",
    kyc_id: kyc.kyc_id || "N/A",
    kyc_type: Utils.replaceUnderscoreWithSpace(kyc.kyc_type),
    status: Utils.replaceUnderscoreWithSpace(kyc.status),
  }));

  return (
    <div className="dashboard-container">
      <SideMenu />
      <div className="main-screen">
        <Header />
        <div className="page-container-campaignList">
          {isLoading ? (
            <div className="loader-mid">
              <Loader />
            </div>
          ) : (
            <>
              <div className="m-b-12 m-l-20">
                <CustomText
                  p
                  text={"Kyc List"}
                  textColor={"text_color_gray_500"}
                  fontSize={"font_size_h7"}
                  textAlign={"text_align_left"}
                  fontWeight={"Inter_Bold"}
                  lineHeight={"line_height_24"}
                />
              </div>
              {/* ----------- List  --------- */}
              {kycListData.length !== 0 ? (
                <div className="custom-table">
                  <CustomTable
                    className="CustomTable"
                    tableSchema={kycTableSchema}
                    paginationPerPage={10}
                    tableData={tableData}
                    customStyles={customStyles}
                    onRowClicked={(item) => {
                      kycDetailPageNavigate(item.kyc_id);
                    }}
                    paginationTotalRows={kycListData.length}
                    pagination
                  />
                </div>
              ) : (
                <div className="fallback">
                  <FallBack heading={"No Kyc List Available"} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default KycList;
