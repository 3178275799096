"use-strict";

import * as Actions from "../actions";

const AppDataReducer = (state = { AppData: {} }, action) => {
  if (action.type === Actions.UPDATE_ADMIN_DATA) {
    state = action.payload;
  }
  console.log("state in redux ", state);

  return state;
};

export default AppDataReducer;
