import React from "react";

// Import Redux State (Private scope)
import { useDispatch } from "react-redux";

import * as Actions from "../redux/actions";

import { CustomText, Image, MenuItem } from "../components";
import { NavLink, useLocation } from "react-router-dom";
import ReactGA from "react-ga";
// Import Redux State (Private scope)
import { useSelector } from "react-redux";

import {
  CampaignsIcon,
  ReportsIcon,
  DashboardIcon,
  ManageTeamIcon,
  SettingIcon,
  TransactionIcon,
  UserIcon,
  FilterIcon,
  orgIcon,
} from "../components/assets/icons";
import { useState } from "react";
import { useEffect } from "react";
import Core from "../common/clientSdk";
import Sdk from "../common/core/module";
import Icon from "../components/core_component/icon";
import WebStorage from "../common/web_storage";
import { BRAND_ID_KEY } from "../common/web_storage_keys";
import { useSnackbar } from "react-simple-snackbar";

const SideMenu = function (props) {
  const [userData, setUserdata] = useState({});
  const [assignmentList, setAssignmentList] = useState([]);
  const [openSnackbar] = useSnackbar();
  const [brandList, setBrandList] = useState([]);

  var redux_selector = useSelector((state) => {
    return {
      admin_data: state.AppDataReducer,
    };
  });

  const menu_item_data_admin = [
    {
      title: "Dashboard",
      svgIconName: "DashboardIcon",
      svgIconNameActive: "DashboardIconActive",
      isProtected: true,
      navlink: "/",
    },
    {
      title: "Database",
      svgIconName: "discoveryInfluencerIcon",
      svgIconNameActive: "discoveryInfluencerActiveIcon",
      navlink: "/influencer-list",
      isProtected: true,
    },
    {
      title: "Campaigns",
      svgIconName: "CampaignsIcon",
      svgIconNameActive: "CampaignsIconActive",
      navlink: "/campaigns",
    },
    {
      title: "Collections",
      svgIconName: "collectionIcons",
      svgIconNameActive: "collectionIconsActive",
      navlink: "/collection",
    },
    {
      title: "Organisations",
      svgIconName: "orgIcon",
      svgIconNameActive: "orgIconActive",
      navlink: "/organisations",
    },

    // {
    //   title: "Contracts",
    //   svgIconName: "contractIcon",
    //   svgIconNameActive: "contractIconActive",
    //   navlink: "/contracts",
    // },

    {
      title: "Contracts",
      svgIconName: "contractIcon",
      svgIconNameActive: "contractIconActive",
      navlink: "/contracts-admin",
    },
    {
      title: "Notification",
      svgIconName: "notificationsIconsActive",
      svgIconNameActive: "notificationsIconsActive",
      navlink: "/notificationPage",
      isProtected: true,
    },
    // {
    //   title: "Creatives",
    //   svgIconName: "creativesIcons",
    //   svgIconNameActive: "creativesIconsActive",
    //   navlink: "/content-view",
    // },
    {
      title: "KYC",
      svgIconName: "KYCIconsActive",
      svgIconNameActive: "KYCIconsActive",
      navlink: "/kyc-list",
      isProtected: true,
    },
    {
      title: "Payout",
      svgIconName: "PayoutIconsActive",
      svgIconNameActive: "PayoutIconsActive",
      navlink: "/payoutListAdmin",
      isProtected: true,
    },
    
    // {
    //   title: "Chats",
    //   svgIconName: "chatInactiveSideBarIcon",
    //   svgIconNameActive: "chatIConsSideBar",
    //   navlink: "/message",
    //   // hasPendingMessages: pendingMessage.length > 0,
    // },
    // {
    //   title: "Wallet",
    //   svgIconName: "TransactionIcon",
    //   svgIconNameActive: "TransactionIconActive",
    //   navlink: "/transaction-list",
    // },
    // {
    //   title: "Profile",
    //   svgIconName: "UserProfileIcon",
    //   svgIconNameActive: "UserProfileIconActive",
    //   navlink: "/user-settings",
    // },
    // {
    //   title: "Script",
    //   svgIconName: "UserProfileIcon",
    //   svgIconNameActive: "UserProfileIconActive",
    //   navlink: "/script",
    // },

    // {
    //   title: "Brand Settings",
    //   svgIconName: "BrandSetting",
    //   svgIconNameActive: "BrandSettingActive",
    //   navlink: "/brand-settings",
    // },

    // {
    //   title: "Team",
    //   svgIconName: "ManageTeamIcon",
    //   svgIconNameActive: "ManageTeamIconActive",
    //   navlink: "/team-members-list",
    // },
    // {
    //   title: "Company",
    //   svgIconName: "CompanyIcon",
    //   svgIconNameActive: "CompanyIconActive",
    //   navlink: "/settings",
    // },
  ];

  // const menu_item_data = [
  //   {
  //     title: "Dashboard",
  //     svgIconName: "DashboardIcon",
  //     svgIconNameActive: "DashboardIconActive",
  //     navlink: "/",
  //   },
  //   {
  //     title: "Influencers",
  //     svgIconName: "discoveryInfluencerIcon",
  //     svgIconNameActive: "discoveryInfluencerActiveIcon",
  //     navlink: "/influencer-list",
  //   },
  //   {
  //     title: "Campaigns",
  //     svgIconName: "CampaignsIcon",
  //     svgIconNameActive: "CampaignsIconActive",
  //     navlink: "/campaigns",
  //   },

  //   {
  //     title: "Contracts",
  //     svgIconName: "contractIcon",
  //     svgIconNameActive: "contractIconActive",
  //     navlink: "/contracts",
  //   },
  //   {
  //     title: "Creatives",
  //     svgIconName: "creativesIcons",
  //     svgIconNameActive: "creativesIconsActive",
  //     navlink: "/content-view",
  //   },
  //   {
  //     title: "Collections",
  //     svgIconName: "collectionIcons",
  //     svgIconNameActive: "collectionIconsActive",
  //     navlink: "/collection",
  //   },

  //   {
  //     title: "Transactions",
  //     svgIconName: "TransactionIcon",
  //     svgIconNameActive: "TransactionIconActive",
  //     navlink: "/transaction-list",
  //   },
  //   {
  //     title: "Payout",
  //     svgIconName: "PayoutIcon",
  //     svgIconNameActive: "PayoutIconActive",
  //     navlink: "/payout-list",
  //   },
  // ];

  const location = useLocation();
  const currentPath = "/" + location.pathname.split("/")[1];

  return (
    <div className="side-menu">
      {/* dropdown Top for brand` */}
      <div className="dropdown">
        <div className="menu-text-dropdown">
          {/* <Image source={"../assets/images/reelax-new-logo.svg"} height={50} /> */}
          {/* <Icon
            iconName={"ProfileDropDownIcon"}
            height={7.41}
            width={12}
            fill="#FFF"
          /> */}
        </div>

        {/* <div className="dropdown-content">
          <ul className="menu-items">
            {brandList.map((item, index) => {
              return (
                <li key={index} className={"menu-text"}>
                  <NavLink
                    to={"#"}
                    onClick={() =>
                      getBrandData(progress_cb, item.brand_data.brand_id)
                    }>
                    <span>
                      <CustomText
                        p
                        text={item.brand_data.name}
                        fontSize={"font_size_large"}
                        textAlign={"text_align_left"}
                        fontWeight={"rubik_regular"}
                        textColor={"text_color_tertiary"}
                      />
                    </span>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div> */}
      </div>

      <div className="section">
        <div className="main-menu">
          <ul className="menu-items">
            <>
              {menu_item_data_admin.map((items, index) => {
                const isSuperAdmin =
                  redux_selector["admin_data"]["role"] === "SUPER-ADMIN";
                const shouldRender = isSuperAdmin || !items.isProtected;

                if (shouldRender) {
                  return (
                    <MenuItem
                      key={index}
                      svgIconName={items.svgIconName}
                      svgIconNameActive={items.svgIconNameActive}
                      title={items.title}
                      navlink={items.navlink}
                      currentPath={currentPath}
                      hasPendingMessages={items.hasPendingMessages}
                    />
                  );
                }
                return null; // Explicitly return null for clarity
              })}
            </>

            {/* {role == "MEMBER" && (
              <>
                {menu_item_data.map((items, index) => {
                  return (
                    <MenuItem
                      key={index}
                      svgIconName={items.svgIconName}
                      svgIconNameActive={items.svgIconNameActive}
                      title={items.title}
                      navlink={items.navlink}
                      currentPath={currentPath}
                    />
                  );
                })}
              </>
            )} */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
