import React from "react";
import { CustomText, Image, TeamMembers } from "../../components";
import SideMenu from "../sideMenu";
import Header from "../header";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import Core from "../../common/clientSdk";
import { Loader } from "../loader";
import utils from "../../common/utils";
import { useNavigate } from "react-router-dom";
import Style from "../../components/core_component/style";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSnackbar } from "react-simple-snackbar";
import ReportSidebar from "../report-sidebar";
import BrandUpdateSetting from "./brandUpdateSetting";
import CompanySettingScreen from "./companySettingScreen";
import NotificationSetting from "./notificationSetting";

function SettingScreen() {
  const [orgData, setOrgData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [activeTab, setActiveTab] = useState("detail-page");
  const [addressData, setAddressData] = useState({});

  var navigate = useNavigate();

  useEffect(() => {
    getOrgDataUser();
  }, []);

  var redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  function updateProgress(status) {
    setLoading(status);
  }

  const { BrandUser = {} } = redux_selector;
  const { user_data = {} } = BrandUser;

  const { brand_deep_data = {} } = BrandUser;
  const { brand_data = {} } = brand_deep_data;

  function getOrgDataUser() {
    const params = { org_id: brand_data.org_id };
    Core.getOrgDataUser(
      getOrgDataUserSuccess,
      getOrgDataUserFailed,
      updateProgress,
      params
    );
  }

  function getOrgDataUserSuccess(response_data) {
    setAddressData(response_data.org_data.address_data);
    setOrgData(response_data.org_data);
    setLoading(false);
  }

  function getOrgDataUserFailed(errorList) {
    toast.info(errorList.map((item) => item.m).join(", "));
  }

  const { address_data = {}, org_data = {} } = orgData;
  const { is_kyc_done = {} } = org_data;

  function EmailVerifyList() {
    return (
      <>
        {!orgData?.org_data?.is_verified ? (
          <div style={Style.text_pending_color}>Not Verified</div>
        ) : (
          <div style={Style.text_completed_color}>Verified</div>
        )}
      </>
    );
  }

  function verifyEmail() {
    if (!orgData?.org_data?.is_verified) {
      navigate("/email-verify");
    } else {
      toast.info("Email Already Verified");
    }
  }

  function DocKyc() {
    return (
      <>
        {!is_kyc_done ? (
          <div style={Style.text_pending_color}>Pending</div>
        ) : (
          <div style={Style.text_completed_color}>Completed</div>
        )}
      </>
    );
  }

  let companyAddress = address_data.address_premise ? (
    address_data.address_premise +
    ", " +
    address_data.address_street +
    ", " +
    address_data.address_city +
    ", " +
    utils.getStateName(address_data?.address_state)
  ) : (
    <div style={Style.text_pending_color}>Billing info missing</div>
  );

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <div className="dashboard-container">
        <SideMenu />

        <div className="main-screen">
          <Header />
          <div className="setting_page_container">
            <div
              className={
                activeTab === "message-page"
                  ? "internal-message-page-container"
                  : "internal-page-container"
              }>
              <div className="setting_text_heading">
                <CustomText
                  h5
                  text={"Settings"}
                  textColor={"text_color_primary"}
                  fontSize={"font_size_2xl"}
                  height={"line_height_24"}
                />
              </div>
              <div className="brand_detail_container">
                <div className="brand_detail_upper_container">
                  <div className="campaign-detail-tab-header">
                    <div
                      className={activeTab === "detail-page" ? "active" : ""}
                      onClick={() => handleTabClick("detail-page")}>
                      <div className="campaign-detail-btn-tabs">
                        <CustomText
                          p
                          // textColor={"text_color_primary"}
                          fontSize={"font_size_medium"}
                          text={"Brand Settings"}
                          height={"line_height_22_5"}
                          fontWeight={"Inter_medium_500"}
                        />
                      </div>
                    </div>
                    <div
                      className={activeTab === "message-page" ? "active" : ""}
                      onClick={() => handleTabClick("message-page")}>
                      <div className="campaign-detail-btn-tabs">
                        <CustomText
                          p
                          // textColor={"text_color_primary"}
                          fontSize={"font_size_medium"}
                          text={"Company Details"}
                          height={"line_height_22_5"}
                          fontWeight={"Inter_medium_500"}
                        />
                      </div>
                    </div>
                    <div
                      className={
                        activeTab === "notification-page" ? "active" : ""
                      }
                      onClick={() => handleTabClick("notification-page")}>
                      <div className="campaign-detail-btn-tabs">
                        <CustomText
                          p
                          // textColor={"text_color_primary"}
                          fontSize={"font_size_medium"}
                          text={"Notification Settings"}
                          height={"line_height_22_5"}
                          fontWeight={"Inter_medium_500"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {activeTab === "detail-page" && <BrandUpdateSetting />}
                {activeTab === "message-page" && (
                  <CompanySettingScreen
                    addressData={addressData}
                    getOrgDataUser={getOrgDataUser}
                  />
                )}
                {activeTab === "notification-page" && <NotificationSetting />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SettingScreen;
