module.exports = {
  countries: [
    {
      id: "AD",
      label: "Andorra",
      calling_code: "+376",
    },
    {
      id: "AE",
      label: "United Arab Emirates",
      calling_code: "+971",
    },
    {
      id: "AF",
      label: "Afghanistan",
      calling_code: "+93",
    },
    {
      id: "AG",
      label: "Antigua and Barbuda",
      calling_code: "+1-268",
    },
    {
      id: "AI",
      label: "Anguilla",
      calling_code: "+1-264",
    },
    {
      id: "AL",
      label: "Albania",
      calling_code: "+355",
    },
    {
      id: "AM",
      label: "Armenia",
      calling_code: "+374",
    },
    {
      id: "AO",
      label: "Angola",
      calling_code: "+244",
    },
    {
      id: "AQ",
      label: "Antarctica",
      calling_code: "",
    },
    {
      id: "AR",
      label: "Argentina",
      calling_code: "+54",
    },
    {
      id: "AS",
      label: "American Samoa",
      calling_code: "+1-684",
    },
    {
      id: "AT",
      label: "Austria",
      calling_code: "+43",
    },
    {
      id: "AU",
      label: "Australia",
      calling_code: "+61",
    },
    {
      id: "AW",
      label: "Aruba",
      calling_code: "+297",
    },
    {
      id: "AX",
      label: "Aland Islands",
      calling_code: "+358",
    },
    {
      id: "AZ",
      label: "Azerbaijan",
      calling_code: "+994",
    },
    {
      id: "BA",
      label: "Bosnia and Herzegovina",
      calling_code: "+387",
    },
    {
      id: "BB",
      label: "Barbados",
      calling_code: "+1-246",
    },
    {
      id: "BD",
      label: "Bangladesh",
      calling_code: "+88",
    },
    {
      id: "BE",
      label: "Belgium",
      calling_code: "+32",
    },
    {
      id: "BF",
      label: "Burkina Faso",
      calling_code: "+226",
    },
    {
      id: "BG",
      label: "Bulgaria",
      calling_code: "+359",
    },
    {
      id: "BH",
      label: "Bahrain",
      calling_code: "+973",
    },
    {
      id: "BI",
      label: "Burundi",
      calling_code: "+257",
    },
    {
      id: "BJ",
      label: "Benin",
      calling_code: "+229",
    },
    {
      id: "BL",
      label: "Saint Barthelemy",
      calling_code: "+590",
    },
    {
      id: "BM",
      label: "Bermuda",
      calling_code: "+1-441",
    },
    {
      id: "BN",
      label: "Brunei Darussalam",
      calling_code: "+673",
    },
    {
      id: "BO",
      label: "Bolivia",
      calling_code: "+591",
    },
    {
      id: "BQ",
      label: "Bonaire, Sint Eustatius and Saba",
      calling_code: "+599",
    },
    {
      id: "BR",
      label: "Brazil",
      calling_code: "+55",
    },
    {
      id: "BS",
      label: "Bahamas",
      calling_code: "+1-242",
    },
    {
      id: "BT",
      label: "Bhutan",
      calling_code: "+975",
    },
    {
      id: "BV",
      label: "Bouvet Island",
      calling_code: "",
    },
    {
      id: "BW",
      label: "Botswana",
      calling_code: "+267",
    },
    {
      id: "BY",
      label: "Belarus",
      calling_code: "+375",
    },
    {
      id: "BZ",
      label: "Belize",
      calling_code: "+501",
    },
    {
      id: "CA",
      label: "Canada",
      calling_code: "+1",
    },
    {
      id: "CC",
      label: "Cocos (Keeling) Islands",
      calling_code: "+61",
    },
    {
      id: "CD",
      label: "Congo, Democratic Republic of the",
      calling_code: "+243",
    },
    {
      id: "CF",
      label: "Central African Republic",
      calling_code: "+236",
    },
    {
      id: "CG",
      label: "Congo",
      calling_code: "+242",
    },
    {
      id: "CH",
      label: "Switzerland",
      calling_code: "+41",
    },
    {
      id: "CI",
      label: "Cote d'Ivoire",
      calling_code: "+225",
    },
    {
      id: "CK",
      label: "Cook Islands",
      calling_code: "+682",
    },
    {
      id: "CL",
      label: "Chile",
      calling_code: "+56",
    },
    {
      id: "CM",
      label: "Cameroon",
      calling_code: "+237",
    },
    {
      id: "CN",
      label: "China",
      calling_code: "+86",
    },
    {
      id: "CO",
      label: "Colombia",
      calling_code: "+57",
    },
    {
      id: "CR",
      label: "Costa Rica",
      calling_code: "+506",
    },
    {
      id: "CU",
      label: "Cuba",
      calling_code: "+53",
    },
    {
      id: "CV",
      label: "Cape Verde",
      calling_code: "+238",
    },
    {
      id: "CW",
      label: "Curacao",
      calling_code: "+599",
    },
    {
      id: "CX",
      label: "Christmas Island",
      calling_code: "+61",
    },
    {
      id: "CY",
      label: "Cyprus",
      calling_code: "+357",
    },
    {
      id: "CZ",
      label: "Czech Republic",
      calling_code: "+420",
    },
    {
      id: "DE",
      label: "Germany",
      calling_code: "+49",
    },
    {
      id: "DJ",
      label: "Djibouti",
      calling_code: "+253",
    },
    {
      id: "DK",
      label: "Denmark",
      calling_code: "+45",
    },
    {
      id: "DM",
      label: "Dominica",
      calling_code: "+1-767",
    },
    {
      id: "DO",
      label: "Dominican Republic",
      calling_code: "+1-809 and 1-829",
    },
    {
      id: "DZ",
      label: "Algeria",
      calling_code: "+213",
    },
    {
      id: "EC",
      label: "Ecuador",
      calling_code: "+593",
    },
    {
      id: "EE",
      label: "Estonia",
      calling_code: "+372",
    },
    {
      id: "EG",
      label: "Egypt",
      calling_code: "+20",
    },
    {
      id: "EH",
      label: "Western Sahara",
      calling_code: "+212",
    },
    {
      id: "ER",
      label: "Eritrea",
      calling_code: "+291",
    },
    {
      id: "ES",
      label: "Spain",
      calling_code: "+34",
    },
    {
      id: "ET",
      label: "Ethiopia",
      calling_code: "+251",
    },
    {
      id: "FI",
      label: "Finland",
      calling_code: "+358",
    },
    {
      id: "FJ",
      label: "Fiji",
      calling_code: "+679",
    },
    {
      id: "FK",
      label: "Falkland Islands (Malvinas)",
      calling_code: "+500",
    },
    {
      id: "FM",
      label: "Micronesia, Federated States of",
      calling_code: "+691",
    },
    {
      id: "FO",
      label: "Faroe Islands",
      calling_code: "+298",
    },
    {
      id: "FR",
      label: "France",
      calling_code: "+33",
    },
    {
      id: "GA",
      label: "Gabon",
      calling_code: "+241",
    },
    {
      id: "GB",
      label: "United Kingdom",
      calling_code: "+44",
    },
    {
      id: "GD",
      label: "Grenada",
      calling_code: "+1-473",
    },
    {
      id: "GE",
      label: "Georgia",
      calling_code: "+995",
    },
    {
      id: "GF",
      label: "French Guiana",
      calling_code: "+594",
    },
    {
      id: "GG",
      label: "Guernsey",
      calling_code: "+44",
    },
    {
      id: "GH",
      label: "Ghana",
      calling_code: "+233",
    },
    {
      id: "GI",
      label: "Gibraltar",
      calling_code: "+350",
    },
    {
      id: "GL",
      label: "Greenland",
      calling_code: "+299",
    },
    {
      id: "GM",
      label: "Gambia",
      calling_code: "+220",
    },
    {
      id: "GN",
      label: "Guinea",
      calling_code: "+224",
    },
    {
      id: "GP",
      label: "Guadeloupe",
      calling_code: "+590",
    },
    {
      id: "GQ",
      label: "Equatorial Guinea",
      calling_code: "+240",
    },
    {
      id: "GR",
      label: "Greece",
      calling_code: "+30",
    },
    {
      id: "GS",
      label: "South Georgia and the South Sandwich Islands",
      calling_code: "",
    },
    {
      id: "GT",
      label: "Guatemala",
      calling_code: "+502",
    },
    {
      id: "GU",
      label: "Guam",
      calling_code: "+1-671",
    },
    {
      id: "GW",
      label: "Guinea-Bissau",
      calling_code: "+245",
    },
    {
      id: "GY",
      label: "Guyana",
      calling_code: "+592",
    },
    {
      id: "HK",
      label: "Hong Kong",
      calling_code: "+852",
    },
    {
      id: "HM",
      label: "Heard Island and McDonald Islands",
      calling_code: "",
    },
    {
      id: "HN",
      label: "Honduras",
      calling_code: "+504",
    },
    {
      id: "HR",
      label: "Croatia",
      calling_code: "+385",
    },
    {
      id: "HT",
      label: "Haiti",
      calling_code: "+509",
    },
    {
      id: "HU",
      label: "Hungary",
      calling_code: "+36",
    },
    {
      id: "ID",
      label: "Indonesia",
      calling_code: "+62",
    },
    {
      id: "IE",
      label: "Ireland",
      calling_code: "+353",
    },
    {
      id: "IL",
      label: "Israel",
      calling_code: "+972",
    },
    {
      id: "IM",
      label: "Isle of Man",
      calling_code: "+44",
    },
    {
      id: "IN",
      label: "India",
      calling_code: "+91",
    },
    {
      id: "IO",
      label: "British Indian Ocean Territory",
      calling_code: "+246",
    },
    {
      id: "IQ",
      label: "Iraq",
      calling_code: "+964",
    },
    {
      id: "IR",
      label: "Iran, Islamic Republic of",
      calling_code: "+98",
    },
    {
      id: "IS",
      label: "Iceland",
      calling_code: "+354",
    },
    {
      id: "IT",
      label: "Italy",
      calling_code: "+39",
    },
    {
      id: "JE",
      label: "Jersey",
      calling_code: "+44",
    },
    {
      id: "JM",
      label: "Jamaica",
      calling_code: "+1-876",
    },
    {
      id: "JO",
      label: "Jordan",
      calling_code: "+962",
    },
    {
      id: "JP",
      label: "Japan",
      calling_code: "+81",
    },
    {
      id: "KE",
      label: "Kenya",
      calling_code: "+254",
    },
    {
      id: "KG",
      label: "Kyrgyzstan",
      calling_code: "+996",
    },
    {
      id: "KH",
      label: "Cambodia",
      calling_code: "+855",
    },
    {
      id: "KI",
      label: "Kiribati",
      calling_code: "+686",
    },
    {
      id: "KM",
      label: "Comoros",
      calling_code: "+269",
    },
    {
      id: "KN",
      label: "Saint Kitts and Nevis",
      calling_code: "+1-869",
    },
    {
      id: "KP",
      label: "Korea, Democratic People's Republic of",
      calling_code: "+850",
    },
    {
      id: "KR",
      label: "Korea, Republic of",
      calling_code: "+82",
    },
    {
      id: "KW",
      label: "Kuwait",
      calling_code: "+965",
    },
    {
      id: "KY",
      label: "Cayman Islands",
      calling_code: "+1-345",
    },
    {
      id: "KZ",
      label: "Kazakhstan",
      calling_code: "+7",
    },
    {
      id: "LA",
      label: "Lao People's Democratic Republic",
      calling_code: "+856",
    },
    {
      id: "LB",
      label: "Lebanon",
      calling_code: "+961",
    },
    {
      id: "LC",
      label: "Saint Lucia",
      calling_code: "+1-758",
    },
    {
      id: "LI",
      label: "Liechtenstein",
      calling_code: "+423",
    },
    {
      id: "LK",
      label: "Sri Lanka",
      calling_code: "+94",
    },
    {
      id: "LR",
      label: "Liberia",
      calling_code: "+231",
    },
    {
      id: "LS",
      label: "Lesotho",
      calling_code: "+266",
    },
    {
      id: "LT",
      label: "Lithuania",
      calling_code: "+370",
    },
    {
      id: "LU",
      label: "Luxembourg",
      calling_code: "+352",
    },
    {
      id: "LV",
      label: "Latvia",
      calling_code: "+371",
    },
    {
      id: "LY",
      label: "Libya",
      calling_code: "+218",
    },
    {
      id: "MA",
      label: "Morocco",
      calling_code: "+212",
    },
    {
      id: "MC",
      label: "Monaco",
      calling_code: "+377",
    },
    {
      id: "MD",
      label: "Moldova, Republic of",
      calling_code: "+373",
    },
    {
      id: "ME",
      label: "Montenegro",
      calling_code: "+382",
    },
    {
      id: "MF",
      label: "Saint Martin (French part)",
      calling_code: "+590",
    },
    {
      id: "MG",
      label: "Madagascar",
      calling_code: "+261",
    },
    {
      id: "MH",
      label: "Marshall Islands",
      calling_code: "+692",
    },
    {
      id: "MK",
      label: "Macedonia, the former Yugoslav Republic of",
      calling_code: "+389",
    },
    {
      id: "ML",
      label: "Mali",
      calling_code: "+223",
    },
    {
      id: "MM",
      label: "Myanmar",
      calling_code: "+95",
    },
    {
      id: "MN",
      label: "Mongolia",
      calling_code: "+976",
    },
    {
      id: "MO",
      label: "Macao",
      calling_code: "+853",
    },
    {
      id: "MP",
      label: "Northern Mariana Islands",
      calling_code: "+1-670",
    },
    {
      id: "MQ",
      label: "Martinique",
      calling_code: "+596",
    },
    {
      id: "MR",
      label: "Mauritania",
      calling_code: "+222",
    },
    {
      id: "MS",
      label: "Montserrat",
      calling_code: "+1-664",
    },
    {
      id: "MT",
      label: "Malta",
      calling_code: "+356",
    },
    {
      id: "MU",
      label: "Mauritius",
      calling_code: "+230",
    },
    {
      id: "MV",
      label: "Maldives",
      calling_code: "+960",
    },
    {
      id: "MW",
      label: "Malawi",
      calling_code: "+265",
    },
    {
      id: "MX",
      label: "Mexico",
      calling_code: "+52",
    },
    {
      id: "MY",
      label: "Malaysia",
      calling_code: "+60",
    },
    {
      id: "MZ",
      label: "Mozambique",
      calling_code: "+258",
    },
    {
      id: "NA",
      label: "Namibia",
      calling_code: "+264",
    },
    {
      id: "NC",
      label: "New Caledonia",
      calling_code: "+687",
    },
    {
      id: "NE",
      label: "Niger",
      calling_code: "+227",
    },
    {
      id: "NF",
      label: "Norfolk Island",
      calling_code: "+672",
    },
    {
      id: "NG",
      label: "Nigeria",
      calling_code: "+234",
    },
    {
      id: "NI",
      label: "Nicaragua",
      calling_code: "+505",
    },
    {
      id: "NL",
      label: "Netherlands",
      calling_code: "+31",
    },
    {
      id: "NO",
      label: "Norway",
      calling_code: "+47",
    },
    {
      id: "NP",
      label: "Nepal",
      calling_code: "+977",
    },
    {
      id: "NR",
      label: "Nauru",
      calling_code: "+674",
    },
    {
      id: "NU",
      label: "Niue",
      calling_code: "+683",
    },
    {
      id: "NZ",
      label: "New Zealand",
      calling_code: "+64",
    },
    {
      id: "OM",
      label: "Oman",
      calling_code: "+968",
    },
    {
      id: "PA",
      label: "Panama",
      calling_code: "+507",
    },
    {
      id: "PE",
      label: "Peru",
      calling_code: "+51",
    },
    {
      id: "PF",
      label: "French Polynesia",
      calling_code: "+689",
    },
    {
      id: "PG",
      label: "Papua New Guinea",
      calling_code: "+675",
    },
    {
      id: "PH",
      label: "Philippines",
      calling_code: "+63",
    },
    {
      id: "PK",
      label: "Pakistan",
      calling_code: "+92",
    },
    {
      id: "PL",
      label: "Poland",
      calling_code: "+48",
    },
    {
      id: "PM",
      label: "Saint Pierre and Miquelon",
      calling_code: "+508",
    },
    {
      id: "PN",
      label: "Pitcairn",
      calling_code: "",
    },
    {
      id: "PR",
      label: "Puerto Rico",
      calling_code: "+1-787 and 1-939",
    },
    {
      id: "PS",
      label: "Palestinian Territory, Occupied",
      calling_code: "+970",
    },
    {
      id: "PT",
      label: "Portugal",
      calling_code: "+351",
    },
    {
      id: "PW",
      label: "Palau",
      calling_code: "+680",
    },
    {
      id: "PY",
      label: "Paraguay",
      calling_code: "+595",
    },
    {
      id: "QA",
      label: "Qatar",
      calling_code: "+974",
    },
    {
      id: "RE",
      label: "Reunion",
      calling_code: "+262",
    },
    {
      id: "RO",
      label: "Romania",
      calling_code: "+40",
    },
    {
      id: "RS",
      label: "Serbia",
      calling_code: "+381",
    },
    {
      id: "RU",
      label: "Russian Federation",
      calling_code: "+7",
    },
    {
      id: "RW",
      label: "Rwanda",
      calling_code: "+250",
    },
    {
      id: "SA",
      label: "Saudi Arabia",
      calling_code: "+966",
    },
    {
      id: "SB",
      label: "Solomon Islands",
      calling_code: "+677",
    },
    {
      id: "SC",
      label: "Seychelles",
      calling_code: "+248",
    },
    {
      id: "SD",
      label: "Sudan",
      calling_code: "+249",
    },
    {
      id: "SE",
      label: "Sweden",
      calling_code: "+46",
    },
    {
      id: "SG",
      label: "Singapore",
      calling_code: "+65",
    },
    {
      id: "SH",
      label: "Saint Helena, Ascension and Tristan da Cunha",
      calling_code: "+290",
    },
    {
      id: "SI",
      label: "Slovenia",
      calling_code: "+386",
    },
    {
      id: "SJ",
      label: "Svalbard and Jan Mayen",
      calling_code: "+47",
    },
    {
      id: "SK",
      label: "Slovakia",
      calling_code: "+421",
    },
    {
      id: "SL",
      label: "Sierra Leone",
      calling_code: "+232",
    },
    {
      id: "SM",
      label: "San Marino",
      calling_code: "+378",
    },
    {
      id: "SN",
      label: "Senegal",
      calling_code: "+221",
    },
    {
      id: "SO",
      label: "Somalia",
      calling_code: "+252",
    },
    {
      id: "SR",
      label: "Suriname",
      calling_code: "+597",
    },
    {
      id: "SS",
      label: "South Sudan",
      calling_code: "+211",
    },
    {
      id: "ST",
      label: "Sao Tome and Principe",
      calling_code: "+239",
    },
    {
      id: "SV",
      label: "El Salvador",
      calling_code: "+503",
    },
    {
      id: "SX",
      label: "Sint Maarten (Dutch part)",
      calling_code: "+1-721",
    },
    {
      id: "SY",
      label: "Syrian Arab Republic",
      calling_code: "+963",
    },
    {
      id: "SZ",
      label: "Swaziland",
      calling_code: "+268",
    },
    {
      id: "TC",
      label: "Turks and Caicos Islands",
      calling_code: "+1-649",
    },
    {
      id: "TD",
      label: "Chad",
      calling_code: "+235",
    },
    {
      id: "TF",
      label: "French Southern Territories",
      calling_code: "",
    },
    {
      id: "TG",
      label: "Togo",
      calling_code: "+228",
    },
    {
      id: "TH",
      label: "Thailand",
      calling_code: "+66",
    },
    {
      id: "TJ",
      label: "Tajikistan",
      calling_code: "+992",
    },
    {
      id: "TK",
      label: "Tokelau",
      calling_code: "+690",
    },
    {
      id: "TL",
      label: "Timor-Leste",
      calling_code: "+670",
    },
    {
      id: "TM",
      label: "Turkmenistan",
      calling_code: "+993",
    },
    {
      id: "TN",
      label: "Tunisia",
      calling_code: "+216",
    },
    {
      id: "TO",
      label: "Tonga",
      calling_code: "+676",
    },
    {
      id: "TR",
      label: "Turkey",
      calling_code: "+90",
    },
    {
      id: "TT",
      label: "Trinidad and Tobago",
      calling_code: "+1-868",
    },
    {
      id: "TV",
      label: "Tuvalu",
      calling_code: "+688",
    },
    {
      id: "TW",
      label: "Taiwan, Province of China",
      calling_code: "+886",
    },
    {
      id: "TZ",
      label: "Tanzania, United Republic of",
      calling_code: "+255",
    },
    {
      id: "UA",
      label: "Ukraine",
      calling_code: "+380",
    },
    {
      id: "UG",
      label: "Uganda",
      calling_code: "+256",
    },
    {
      id: "UM",
      label: "United States Minor Outlying Islands",
      calling_code: "",
    },
    {
      id: "US",
      label: "United States",
      calling_code: "+1",
    },
    {
      id: "UY",
      label: "Uruguay",
      calling_code: "+598",
    },
    {
      id: "UZ",
      label: "Uzbekistan",
      calling_code: "+998",
    },
    {
      id: "VA",
      label: "Holy See (Vatican City State)",
      calling_code: "+379",
    },
    {
      id: "VC",
      label: "Saint Vincent and the Grenadines",
      calling_code: "+1-784",
    },
    {
      id: "VE",
      label: "Venezuela, Bolivarian Republic of",
      calling_code: "+58",
    },
    {
      id: "VG",
      label: "Virgin Islands, British",
      calling_code: "+1-284",
    },
    {
      id: "VI",
      label: "Virgin Islands, U.S.",
      calling_code: "+1-340",
    },
    {
      id: "VN",
      label: "Vietnam",
      calling_code: "+84",
    },
    {
      id: "VU",
      label: "Vanuatu",
      calling_code: "+678",
    },
    {
      id: "WF",
      label: "Wallis and Futuna",
      calling_code: "+681",
    },
    {
      id: "WS",
      label: "Samoa",
      calling_code: "+685",
    },
    {
      id: "YE",
      label: "Yemen",
      calling_code: "+967",
    },
    {
      id: "YT",
      label: "Mayotte",
      calling_code: "+262",
    },
    {
      id: "ZA",
      label: "South Africa",
      calling_code: "+27",
    },
    {
      id: "ZM",
      label: "Zambia",
      calling_code: "+260",
    },
    {
      id: "ZW",
      label: "Zimbabwe",
      calling_code: "+263",
    },
  ],
};
