import React, { useState, useEffect } from "react";
import { CustomText, PrimaryCta } from "../../components";

import { ToastContainer, toast } from "react-toastify";
import Core from "../../common/clientSdk";
import Icon from "../../components/core_component/icon";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import * as LoadingUI from "../../resources/data/loading.json";
import Lottie from "react-lottie";
import Utils from "../../common/utils";
const ExpandedComponent = ({ item, brand_deep_data }) => {
  const [contentAnalyticsData, setContentAnalyticsData] = useState("");
  const [loading, setLoading] = useState(false);
  const data = item.data;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingUI,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (data.campaign_data.campaign_type !== "content") {
      AssignmentContentAnalytics(data);
    }
  }, []);

  function updateProgress(status) {
    setLoading(status);
  }

  function AssignmentContentAnalytics() {
    const params = {
      org_id: brand_deep_data.brand_data.org_id,
      brand_id: brand_deep_data.brand_data.brand_id,
      campaign_id: data.campaign_data.campaign_id,
      assignment_id: data.assignment_data.assignment_id,
      content_id: data.assignment_data.content_id,
      media_url: data.assignment_data.url,
    };
    Core.AssignmentContentListByContent(
      AssignmentContentAnalyticsSuccess,
      AssignmentContentAnalyticsFailed,
      updateProgress,
      params
    );
  }

  function AssignmentContentAnalyticsSuccess(response) {
    setContentAnalyticsData(response.content_analytics_data);
  }
  function AssignmentContentAnalyticsFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  return (
    <>
      {loading ? (
        <div
          style={{
            color: "black",
            display: "flex",
            flexDirection: "row",
            height: 20,

            alignItems: "center",
          }}
        >
          <Lottie options={defaultOptions} height={15} width={100} />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 30,
            alignItems: "center",
            //  justifyContent: "center",
            width: 500,
            height: 50,
            marginLeft: 40,
          }}
        >
          {data.campaign_data.campaign_type == "content" ? (
            <div>
              <CustomText
                p
                fontSize={"font_size_medium"}
                textColor={"text_color_primary"}
                text={"Data Not Available for UGC Content"}
              />
            </div>
          ) : (
            <>
              {" "}
              <div className="flex-row ">
                <Icon iconName="heartIcon" />
                <CustomText
                  p
                  text={Utils.convertToK(contentAnalyticsData.like_count)}
                  className="subtitle1 m-l-5"
                />
              </div>
              <div className="flex-row">
                <Icon iconName="commentCount" />
                <CustomText
                  p
                  text={Utils.convertToK(contentAnalyticsData.comment_count)}
                  className="subtitle1 m-l-5"
                />
              </div>
              <div className="flex-row">
                <Icon iconName="viewEyes" />
                <CustomText
                  p
                  text={Utils.convertToK(contentAnalyticsData.play_count)}
                  className="subtitle1 m-l-5"
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ExpandedComponent;
